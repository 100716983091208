<template>
    <div>
        <div class="board-title">
            <p class="rm">Услуга: {{ tenderServiceName }}</p>
        </div>
        <div class="board-inf">
            <div class="col-v10 mrb24">
                <div class="inp disabled "
                     :class="{error:error.name}">
                    <p class="inpName">Название тендера</p>
                    <input type="text"
                           :value="name"
                           maxlength="255"
                           @input="setName($event.target.value)"
                           placeholder="Название тендера">
                    <span class="err ion-a-close"></span>
                    <p class="inpErr">{{ error.name }}</p>
                </div>
            </div>

            <div class="col-v10 row-wrap mrb24">
                <div class="col-v5 col-p10 mrb24"
                >
                    <div class="sel"
                         :class="{error:error.industry}">
                        <v-select v-model="industry"
                                  @input="clearSubIndustry"
                                  :options="$store.state.industry.industries">
                            <div slot="no-options">Поиск не дал результатов</div>
                        </v-select>
                        <p v-if="error.industry"
                           class="inpErr">{{ error.industry }}</p>
                    </div>
                </div>
                <div v-if="industry.id" class="col-v5 col-p10">
                    <div class="sel"
                         :class="{error:error.sub_industry}">
                        <v-select v-model="sub_industry"
                                  :disabled="industry.id === null"
                                  :options="$store.getters['industry/getSubIndustries'](industry.id)">
                            <div slot="no-options">Поиск не дал результатов</div>
                        </v-select>
                        <p v-if="error.sub_industry"
                           class="inpErr">{{ error.sub_industry }}</p>
                    </div>
                </div>
            </div>
            <div class="hr width"></div>

            <div class="col-v10 mrb24">
                <div class="inp disabled pl53"
                     :class="{error:error.tender_portal}">
                    <span class="ic link-ic"></span>
                    <p class="inpName">Ссылка на портал проведения тендера</p>
                    <input type="text"
                           :value="tender_portal"
                           @change="setTender_portal($event.target.value)"
                           placeholder="Ссылка на портал проведения тендера">
                    <span class="err ion-a-close"></span>
                    <p class="inpErr">{{ error.tender_portal }}</p>
                </div>
            </div>
            <div class="col-v10 mrb24">
                <p>Если есть готовое задание или специфические требования, обязательно поделитесь ими с
                    исполнителем. Исполнители лучше поймут задачу и зададут минимум дополнительных
                    вопросов.</p>
            </div>
            <div class="inp inpTextarea disabled "
                 :class="{error:error.description}">
                <p class="inpName">Расскажите о задаче подробнее</p>
                <textarea
                        :value="description"
                        @input="setDescription($event.target.value)"
                        placeholder="Расскажите о задаче подробнее"></textarea>
                <span class="err ion-a-close"></span>
                <p class="inpErr">{{ error.description }}</p>
            </div>

            <div class="col-v10 mrb24">
                <div v-if="ajaxFiles" class="loading loading-files">
                    <div class="spinner"></div>
                    <p>Идет загрузка ...</p>
                </div>
                <label v-if="!ajaxFiles" for="uploadStepFiles" class="wrap-loadFile">
                    <div class="loadFile">
                        <input type="file"
                               id="uploadStepFiles"
                               multiple
                               @change="attachFiles">
                        <div class="col-v10 center">
                            <button class="btn loadFile-ic mrb24">
                                <span class="ion-plus"></span>
                            </button>
                            <div class="wrap-p">
                                <p class="grey mrb24">Загрузите сюда необходимые файлы</p>
                                <p class="grey fs12">Максимальный размер: 20 мб</p>
                            </div>
                        </div>
                    </div>
                </label>
                <div v-if="files.length"
                     class="inpResult mrt24 jc-start">
                    <div v-for="(file,key) in files"
                         class="attach jc-start">
                        <label v-if="file"
                               class="attach-lab ion-a-attach grey ">
                            <a v-if="file.path"
                               target="_blank"
                               :href="file.path">{{file.name }}</a>
                            <span v-if="!file.path">{{file.name}}</span>

                        </label>
                        <span
                                @click.stop.prevent="removeFile(key)"
                                class="ion-a-close"></span>
                    </div>
                </div>
            </div>

            <div class="row-wrap col-v10 ">
                <div class="col-v5 col-p10 mrb16">
                    <div class="inp inpDatePicker disabled"
                         :class="{error:error.start_date}">
                        <!--<input type="text" placeholder="Дата началда тендера">-->
                        <datepicker :placeholder="'Крайняя дата приема заявок'"
                                    :format="'dd.MM.yyyy'"
                                    :language="ru"
                                    :name="'start_date'"
                                    :value="start_date"
                                    :disabledDates="disabledDatesStart"
                                    :monday-first="true"
                                    @input="setStart_date($event); changeStartDate($event);"
                        ></datepicker>
                        <span class="ic-calendar ic"></span>
                        <span class="err ion-a-close"></span>
                        <p class="inpErr">{{ error.start_date }}</p>
                    </div>
                </div>
                <div class="col-v5 col-p10 mrb16">
                    <div class="inp inpDatePicker disabled"
                         :class="{error:error.end_date}">
                        <!--<input type="text" placeholder="Дата окончания тендера">-->
                        <datepicker :placeholder="'Дата окончания тендера'"
                                    :format="'dd.MM.yyyy'"
                                    :language="ru"
                                    :monday-first="true"
                                    :disabledDates="disabledDatesEnd"
                                    :name="'end_date'"
                                    :value="end_date"
                                    @input="setEnd_date($event); changeEndDate($event);"
                                    style="display: block"
                        ></datepicker>
                        <span class="ic-calendar ic"></span>
                        <span class="err ion-a-close"></span>
                        <p class="inpErr">{{ error.end_date }}</p>
                    </div>
                </div>
            </div>
            <div class="col-v10 jc-end mrb24">
                <p class="grey fs12">Дата окончания тендера, в котором Вы планируете принять участие</p>
            </div>
            <div class="col-v10"
                 :class="{mrb24:quickly}">
                <div class="check wrap-helpOutline">
                    <input type="checkbox"
                           id="quick-tender"
                           :value="quickly"
                           :checked="quickly"
                           @change="setQuickly(!!$event.target.checked)"
                           name="quick-tender">
                    <span class="ion-ios-help-outline" v-if="tooltips && tooltips.urgent_tender">
                        <span class="helpOutline-open">
                            <span class="helpOutline-open-text">
                                <p>{{ tooltips.urgent_tender }}</p>
                            </span>
                        </span>
                    </span>
                    <label for="quick-tender" class="check-lb">Срочный тендер<span class="ion-a-done"></span></label>
                </div>
            </div>
            <div class="col-v10"
                 v-if="quickly">
                <vue-timepicker :value="quicklyTime"
                                @change="setQuickly_time($event.data.HH+':'+$event.data.mm+':00')"
                ></vue-timepicker>
            </div>

        </div>
    </div>
</template>

<script>
    import {
        mapMutations,
        mapGetters,
        mapState
    }                    from 'vuex';
    import store         from '../../../../store';
    import VueSelect     from 'vue-select';
    import datepicker    from 'vuejs-datepicker';
    import VueTimepicker from '../../../utils/vue-timepicker';
    import flash         from "../../../../services/flashMsg";

    export default {
        name: "step1",
        data() {
            return {
                ru: window.ru,
                uploadFiles: [],
                disabledDatesStart: {
                    to: new Date()
                },
                disabledDatesEnd: {
                    to: new Date()
                },
                tenderServices: tenderServices,
                tooltips: tooltips ? JSON.parse(tooltips) : null
            };
        },
        store,
        computed: {
            quicklyTime() {
                let qt = this.$store.state.tender.quickly_time,
                    quickTime = [
                        '00',
                        '00'
                    ];
                if (qt) quickTime = qt.split(':');

                return {
                    'HH': quickTime[0],
                    'mm': quickTime[1]
                };
            },
            tenderServiceName() {
                let serviceId = this.$store.state.tender.service_id;
                return serviceId ? tenderServices.find(e => e.id == serviceId).name : '';
            },
            industry: {
                get() {
                    let industry = this.$store.state.industry.industries.find(e => e.id === this.$store.state.tender.industry);
                    return industry ? industry : {
                        id: null,
                        label: 'Выберите отрасль вашего тендера'
                    };
                },
                set(value) {
                    this.setIndustry(value.id);
                }
            },
            sub_industry: {
                get() {
                    let sub_industry = this.$store.getters['industry/getSubIndustries'](this.$store.state.tender.industry).find(e => e.id === this.$store.state.tender.sub_industry);
                    return sub_industry ? sub_industry : {
                        id: null,
                        label: 'Выберите подотрасль вашего тендера'
                    };
                },
                set(value) {
                    this.setSub_industry(value.id);
                }
            },
            error() {
                return this.$store.state.tender.errors;
            }, ...mapState('tender', [
                'files',
                'name',
                'start_date',
                'end_date',
                'quickly',
                'quickly_time',
                'tender_portal',
                'description',
                'ajaxFiles'
            ])
        },
        methods: {
            ...mapMutations('tender', [
                'setName',
                'setStart_date',
                'setEnd_date',
                'setService_id',
                'setIndustry',
                'setSub_industry',
                'setQuickly',
                'setQuickly_time',
                'setTender_portal',
                'setDescription'
            ]),
            clearSubIndustry(newIndustry) {
                return this.industry.id === newIndustry.id ? null : this.setSub_industry({
                    id: null,
                    label: 'Подотрасль'
                });
            },
            attachFiles(e) {
                let maxSize = 20 * 1024 * 1024;
                if (e.target.files.length) {
                    let files = [];

                    for (let i = 0; i < e.target.files.length; i++) {
                        let file = e.target.files[i];
                        if (file.size > maxSize) {
                            flash.add('Файл ' + file.name + ' имеет размер более 20мб', 'error');
                            continue;
                        }
                        files.push(file);
                        console.log('file 1');
                    }

                    this.$store.dispatch('tender/addFiles', files);
                }
            },
            removeFile(key) {
                this.$store.dispatch('tender/removeFile', key);
            },
            changeStartDate(date) {
                this.disabledDatesEnd.to = new Date(date.getTime() + 86400000);
                if (this.end_date && this.end_date < date) {
                    this.setEnd_date(null);
                }
            },
            changeEndDate(date) {
                this.disabledDatesStart.from = new Date(date.getTime() - 86400000);
                this.disabledDatesStart.to = new Date();
                if (this.start_date && this.start_date > date) {
                    this.setStart_date(null);
                }
            }
        },
        components: {
            'v-select': VueSelect,
            'datepicker': datepicker,
            VueTimepicker
        },
        mounted() {
            this.$store.dispatch('industry/getIndustries');
            if (this.start_date)
                this.changeStartDate(new Date(this.start_date));
            if (this.end_date)
                this.changeEndDate(new Date(this.end_date));
        }
    };
</script>

<style lang="scss"
       scoped>
    label[for="uploadStepFiles"] {
        cursor: pointer;
        position: relative;

        button {
            pointer-events: none;
        }

        input {
            position: absolute;
            opacity: 0;
            height: 100%;
            width: 100%;
        }
    }
</style>
