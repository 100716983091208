export default
{
    /**
     *
     * @param text
     * @param type
     * @param time
     */
    add(text, type, time) {
        let $type = type ? type : 'success',
            $time = time ? time : 4;

        $.oc.flashMsg({
            text:     text,
            'class':  $type,
            interval: $time
        });
    }
};
