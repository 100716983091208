import Vue                   from 'vue';
import Vuex                  from 'vuex';
import tender                from './modules/tender';
import geo                   from './modules/geo';
import options               from './modules/options';
import industry              from './modules/industry';
import optionService         from './modules/optionService';
import createLogger          from 'vuex/dist/logger';
import createPersistedState  from 'vuex-persistedstate';
import createMutationsSharer from 'vuex-shared-mutations';

Vue.use(Vuex);
const plugins = [
    createPersistedState(),
    createMutationsSharer({
        predicate: [
            'mutation1',
            'mutation2'
        ]
    })
];
const debug   = process.env.NODE_ENV !== 'production';

if (debug) {
    plugins.push(createLogger());
}

export default new Vuex.Store({
    modules: {
        tender,
        geo,
        industry,
        options,
        optionService,
    },
    strict:  debug,
    plugins: plugins
});
