import Vue from 'vue';

// initial state
const state = {
    services: [],
    checked:  [],
};

// getters
const getters = {
    totalPrice(state, getters, rooState, rootGetters) {
        console.log(getters, rooState, rootGetters);
        let price             = 0,
            middleTenderPrice = rootGetters['tender/middlePrice'];
        if (!state.services.length) return price;
        for (let service of state.services) {
            Vue.$log.debug(service, state.checked.indexOf(service.id));
            if (state.checked.indexOf(service.id) === -1 || !service.value) continue;
            if (service.type === 0) {
                price = price + service.value;
            }
            else if (service.type === 1) {
                price = Math.ceil(price + middleTenderPrice * service.value / 100);
            }
        }
        Vue.$log.debug(price);
        return price;
    }
};

// actions
const actions = {
    setOptionServices: ({state, commit}) => {
        commit('setOptionServices', optionServices);
    }
};

// mutations
const mutations = {
    setOptionServices: (state, value) => {
        if (value && value.length) {
            value = value.map(e => {
                e.value = e.value ? Math.ceil(e.value) : 0;
                return e;
            });
        }
        state.services = value;
    },
    changeChecked:     (state, id) => {
        let index = state.checked.findIndex(e => id === e);
        if (index > -1) {
            state.checked = state.checked.filter(e => e !== id);
        }
        else {
            state.checked.push(id);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
