import VueTenders from './index.vue';
import Vue        from "vue";
import VueRouter  from 'vue-router';

Vue.use(VueRouter);
const router = new VueRouter({
    mode:   'history',
    /*
     We just add one route
     */
    routes: [
        {
            // Wildcard path
            path:      '/tenders',
            // Specify the component to be rendered for this route
            component: VueTenders,
            // Inject  props based on route.query values (our query parameters!)
            props:     (route) => ({
                to:    route.query.to,
                stage: route.query.stage,
                bg:    route.query.bg
            })
        }
    ]
});

export default function () {
    return new Vue({
        el:       '#app-tenders',
        template: `<div id="app-tenders"
class="app-element">
    <router-view></router-view>
</div>`,
        router
    });
}
