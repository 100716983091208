import Vue        from "vue";
import OptionsComponent from './options.vue'

export default function () {

    return new Vue({
        el: '#options-app',
        template: `
<section id="options-app" class="section app-element">
    <options></options>
</section>
        `,
        components: {
            'options': OptionsComponent
        }
    });
}
