<template>
    <div class="ctrBox">
        <h2 class="colorGreen">Регистрация</h2>
        <form>
            <div class="tabs-reg"
                 v-if="step === 1 && !activation_step"
                 ref="container">
                <div class="reg__caption">
                    <div @click="setCustomer"
                         class="reg-link"
                         :class="{active: is_customer === true}"
                    >Я заказчик
                    </div>
                    <div @click="setPerformer"
                         class="reg-link"
                         :class="{active: is_performer === true}"
                    >Я исполнитель
                    </div>
                </div>
                <div class="reg__content active">
                    <label class="inp"
                           :class="{error:error.email}">
                        <p class="inpName">Ваш e-mail</p>
                        <input type="email"
                               v-model="email"
                               placeholder="Ваш e-mail">
                        <span class="err">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="17"
                                     height="17"
                                     viewBox="0 0 17 17"><path fill="none"
                                                               fill-rule="nonzero"
                                                               stroke="#E45C52"
                                                               d="M8.662 8.662l-.177-.177-8.131 8.132h.707l-.707-.707v.707l8.131-8.132L.354.354v.707L1.06.354H.354l8.131 8.131L16.617.354h-.707l.707.707V.354L8.485 8.485l.177.177zm0 0l7.955 7.955v-.707l-.707.707h.707L8.662 8.662z"></path></svg>
                            </span>
                        <p class="inpErr">{{ error.email }}</p>
                    </label>
                    <label class="inp"
                           :class="{error:error.phone}">
                        <p class="inpName">Ваш номер телефона</p>
                        <input type="tel"
                               v-model="phone"
                               v-mask="'+7(###) ###-####'"
                               placeholder="Ваш номер телефона">
                        <span class="err">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="17"
                                     height="17"
                                     viewBox="0 0 17 17"><path fill="none"
                                                               fill-rule="nonzero"
                                                               stroke="#E45C52"
                                                               d="M8.662 8.662l-.177-.177-8.131 8.132h.707l-.707-.707v.707l8.131-8.132L.354.354v.707L1.06.354H.354l8.131 8.131L16.617.354h-.707l.707.707V.354L8.485 8.485l.177.177zm0 0l7.955 7.955v-.707l-.707.707h.707L8.662 8.662z"></path></svg>
                            </span>
                        <p class="inpErr">{{ error.phone }}</p>
                    </label>
                    <div class="hr"></div>
                    <label class="inp"
                           :class="{error:error.password}">
                        <p class="inpName">Пароль</p>
                        <input :type="type.password"
                               v-model="password"
                               ref="password"
                               @input="error.password=''"
                               placeholder="Пароль">
                        <span @click="switchVisibility('password')"
                              class="show-eye"
                              :class="{'ion-a-eye-off':type.password==='password','ion-a-eye-on':type.password==='text'}"></span>
                        <span class="err">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="17"
                                     height="17"
                                     viewBox="0 0 17 17"><path fill="none"
                                                               fill-rule="nonzero"
                                                               stroke="#E45C52"
                                                               d="M8.662 8.662l-.177-.177-8.131 8.132h.707l-.707-.707v.707l8.131-8.132L.354.354v.707L1.06.354H.354l8.131 8.131L16.617.354h-.707l.707.707V.354L8.485 8.485l.177.177zm0 0l7.955 7.955v-.707l-.707.707h.707L8.662 8.662z"></path></svg>
                            </span>
                        <p class="inpErr">{{ error.password }}</p>
                    </label>
                    <label class="inp"
                           :class="{error:error.password_confirmation}">
                        <p class="inpName">Повторите пароль</p>
                        <input :type="type.password_confirmation"
                               v-model="password_confirmation"
                               ref="password_confirmation"
                               @input="error.password_confirmation=''"
                               placeholder="Повторите пароль">
                        <span @click="switchVisibility('password_confirmation')"
                              class="show-eye"
                              :class="{'ion-a-eye-off':type.password_confirmation==='password','ion-a-eye-on':type.password_confirmation==='text'}"></span>
                        <span class="err">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="17"
                                     height="17"
                                     viewBox="0 0 17 17"><path fill="none"
                                                               fill-rule="nonzero"
                                                               stroke="#E45C52"
                                                               d="M8.662 8.662l-.177-.177-8.131 8.132h.707l-.707-.707v.707l8.131-8.132L.354.354v.707L1.06.354H.354l8.131 8.131L16.617.354h-.707l.707.707V.354L8.485 8.485l.177.177zm0 0l7.955 7.955v-.707l-.707.707h.707L8.662 8.662z"></path></svg>
                            </span>
                        <p class="inpErr">{{error.password_confirmation}}</p>
                    </label>
                    <div class="recaptcha"
                         :class="{error:error.captcha}">
                        <vue-recaptcha
                                ref="recaptcha1"
                                @verify="onVerify"
                                @expired="onExpired"
                                :sitekey="recaptcha_site_key">
                        </vue-recaptcha>
                        <p class="inpErr">{{ error.captcha }}</p>
                    </div>
                    <div class="wrap-doorBtn">
                        <div class="wrap-losePass">
                            Нажимая кнопку «Отправить»,
                            <a href="/personal-data" target="_blank"
                               class="losePass">я согласен на обработку моих персональных данных</a> и
                            <a href="/privacy" target="_blank"
                               class="losePass">условиями публичной оферты</a>
                        </div>

                        <div @click="send" class="create btn colorGreen">
                            <div v-if="ajax" class="spinner-btn">
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div>
                            <span class="colorGreen-hover"></span>
                            <span v-if="!ajax" class="zi">Регистрация</span>
                        </div>
                    </div>
                    <div class="wrap-regLink">
                        <div class="regLink-text">Уже зарегестрированы?</div>
                        <a href="/login"
                           class="regLink">Войти</a>
                    </div>
                </div>
            </div>
        </form>
        <div class="activate" v-if="step === 2 || activation_step">

            <div>
                <div class="reg__content"
                     :class="{active:step === 2 || activation_step}">
                    <br>
                    На ваш электронный адрес была отправлена ссылка для активации аккаунта.
                </div>
            </div>
            <!--<form @submit.stop.prevent="activate">-->
                <!--<div class="reg__content"-->
                     <!--:class="{active:step === 2 || activation_step}">-->
                    <!--<br>-->
                    <!--<div class="inp"-->
                         <!--:class="{error:error.activate_code}">-->
                        <!--<p class="inpName">Код активации отправленный на указанный при регистрации e-mail</p>-->
                        <!--<input type="text"-->
                               <!--v-model="activate_code_param"-->
                               <!--placeholder="Код активации">-->
                    <!--</div>-->
                    <!--<div class="wrap-doorBtn">-->
                        <!--<div @click="activate" class="create btn colorGreen">-->
                            <!--<div v-if="ajax" class="spinner-btn">-->
                                <!--<div class="bounce1"></div>-->
                                <!--<div class="bounce2"></div>-->
                                <!--<div class="bounce3"></div>-->
                            <!--</div>-->
                            <!--<span class="colorGreen-hover"></span>-->
                            <!--<span v-if="!ajax" class="zi">Активировать</span>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</form>-->
        </div>
    </div>
</template>

<script>
    import errors       from '../../helpers/errors';
    import VueRecaptcha from 'vue-recaptcha';
    import apiService   from '../../services/api';
    import flash        from "../../services/flashMsg";

    export default {
        name: "register.vue",
        data: function () {
            return {
                recaptcha_site_key: recaptcha_site_key,
                is_performer: false,
                is_customer: true,
                email: '',
                phone: '',
                password: '',
                password_confirmation: '',
                activate_code: '',
                step: 1,
                captcha: false,
                ajax: false,
                error: {
                    email: '',
                    phone: '',
                    password: '',
                    password_confirmation: '',
                    activate_code: '',
                    captcha: ''
                },
                type: {
                    password: 'password',
                    password_confirmation: 'password'
                }
            };
        },
        watch: {
            email: function () {
                this.error.email = '';
            },
            phone: function () {
                this.error.phone = '';
            },
            password: function () {
                this.error.password = '';
            },
            password_confirmation: function () {
                this.error.password_confirmation = '';
            }
        },
        components: {
            'vue-recaptcha': VueRecaptcha
        },
        computed: {
            activate_code_param: {
                get: function () {
                    if (this.activate_code) return this.activate_code;
                    return getParameterByName('activate') ? getParameterByName('activate') : '';
                },
                set: function (value) {
                    this.activate_code = value;
                    return value;
                }
            },
            activation_step: function () {
                return getParameterByName('activate') ? true : false;
            }
        },
        directives: {},
        created() {
        },
        mounted() {
            let type = getParameterByName('type');
            switch (type) {
                case 'customer':
                    this.setCustomer();
                    break;
                case 'performer':
                    this.setPerformer();
                    break;
            }
        },
        methods: {
            switchVisibility(type) {
                this.type[type] = this.type[type] === 'password' ? 'text' : 'password';
                this.$refs[type].focus();
            },
            send: function () {
                let _this = this;

                if (this.validate()) {
                    this.ajax = true;
                    apiService.send('signup', {
                        is_performer: this.is_performer ? 1 : 0,
                        is_customer: this.is_customer ? 1 : 0,
                        email: this.email,
                        phone: this.phone.replace(/[^0-9.]/g, ""),
                        password: this.password,
                        password_confirmation: this.password_confirmation
                    }).then(function (data) {
                        _this.step = 2;
                    }).catch(function (err) {
                        if (err.hasOwnProperty('responseJSON')) {
                            console.log(err.responseJSON.error);
                            flash.add(err.responseJSON.error, 'error');
                        }
                    }).finally(function () {
                        _this.ajax = false;
                    });
                }
            },
            activate: function () {
                let _this = this;

                this.ajax = true;

                apiService.send('activate', {
                    code: this.activate_code_param
                }).then(function (data) {
                    window.location.href = '/';
                }).catch(function (err) {
                    if (err.hasOwnProperty('responseJSON')) {
                        _this.error.activate_code = err.responseJSON.error;
                    }
                }).finally(function () {
                    _this.ajax = false;
                });
            },
            validate: function () {
                let error = false;
                if (!this.email) {
                    this.error.email = errors.empty;
                    error = true;
                } else {
                    if (!validateEmail(this.email)) {
                        this.error.email = errors.email;
                        error = true;
                    }
                }

                if (!this.password) {
                    this.error.password = errors.empty;
                    error = true;
                }

                if (!this.password_confirmation) {
                    this.error.password_confirmation = errors.empty;
                    error = true;
                }

                if (this.password !== this.password_confirmation) {
                    this.error.password_confirmation = errors.password_confirmation;
                    error = true;
                }

                if (this.is_customer) {
                    if (!this.phone) {
                        error = true;
                        this.error.phone = errors.empty;
                    } else {
                        if (this.phone.replace(/[^0-9.]/g, "").length < 11) {
                            error = true;
                            this.error.phone = errors.phone;
                        }
                    }
                }

                if (!this.captcha) {
                    error = true;
                    this.error.captcha = errors.captcha;
                    flash.add(errors.captcha, 'error');
                }

                return !error;
            },
            setCustomer: function () {
                this.is_performer = false;
                this.is_customer = true;
            },
            setPerformer: function () {
                this.is_performer = true;
                this.is_customer = false;
            },
            onSubmit: function () {
                this.$refs.invisibleRecaptcha.execute();
            },
            onVerify: function (response) {
                this.captcha = true;
                this.error.captcha = '';
            },
            onExpired: function () {
                console.log('Expired');
                this.resetRecaptcha();
            },
            resetRecaptcha() {
                this.$refs.recaptcha1.reset(); // Direct call reset method
                this.$refs.recaptcha2.reset(); // Direct call reset method
            }
        }
    };
</script>

<style scoped>

</style>
