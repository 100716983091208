<template>
    <div>
        <div class="inf-sent mrb45" v-if="step === 2">
            <div class="inf-sent-ic">i</div>
                Код выслан на {{ restoreIsEmail ? 'указаную почту' : 'указанный телефон' }}
            <span class="close ion-a-close"></span>
        </div>
        <h2 class="colorGreen">Забыл пароль</h2>
        <div v-if="step === 1 && !activation_step">
            <div class="subText">
                В форму ниже введите свой {{ restoreIsEmail ? "электронный адрес" : "телефон"}}, указанный при
                регистрации,
                <br>
                и через несколько минут на Ваш {{ restoreIsEmail ? "E-mail" : "телефон"}} придет письмо с кодом
            </div>

            <label v-if="restoreIsEmail" class="inp" :class="{error:error.email}">
                <p class="inpName">Ваш e-mail</p>
                <input type="email"
                       v-model="email"
                       name="email"
                       placeholder="Ваш e-mail">
                <span class="err"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17"><path fill="none" fill-rule="nonzero" stroke="#E45C52" d="M8.662 8.662l-.177-.177-8.131 8.132h.707l-.707-.707v.707l8.131-8.132L.354.354v.707L1.06.354H.354l8.131 8.131L16.617.354h-.707l.707.707V.354L8.485 8.485l.177.177zm0 0l7.955 7.955v-.707l-.707.707h.707L8.662 8.662z"></path></svg></span>
                <p class="inpErr">{{ error.email }}</p>
            </label>
            <label v-if="!restoreIsEmail" class="inp" :class="{error:error.phone}">
                <p class="inpName">Ваш телефон</p>
                <input type="tel"
                       v-model="phone"
                       v-mask="'+7(###) ###-####'"
                       name="phone"
                       placeholder="Ваш телефон">
                <span class="err"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17"><path fill="none" fill-rule="nonzero" stroke="#E45C52" d="M8.662 8.662l-.177-.177-8.131 8.132h.707l-.707-.707v.707l8.131-8.132L.354.354v.707L1.06.354H.354l8.131 8.131L16.617.354h-.707l.707.707V.354L8.485 8.485l.177.177zm0 0l7.955 7.955v-.707l-.707.707h.707L8.662 8.662z"></path></svg></span>
                <p class="inpErr">{{ error.phone }}</p>
            </label>
            <div class="wrap-sendCnt indent24">
                <a v-if="restoreIsEmail" class="sendCnt grey" @click="restoreIsEmail = false">или Отправить код на телефон</a>
                <a v-if="!restoreIsEmail" class="sendCnt grey" @click="restoreIsEmail = true">или Отправить код на E-mail</a>
            </div>
            <div class="inp recaptcha indent48"
                 :class="{error:error.captcha}">
                <vue-recaptcha
                        ref="recaptcha2"
                        @verify="onVerify"
                        @expired="onExpired"
                        :sitekey="recaptchaSiteKey"></vue-recaptcha>
                <p class="inpErr">{{ error.captcha }}</p>
            </div>


            <div class="wrap-sendPass">
                <button type="submit" @click="send" class="create btn colorGreen btn360">
                    <span class="colorGreen-hover"></span>
                    <span class="zi">Выслать код на {{ restoreIsEmail ? 'почту' : 'телефон' }}</span>
                </button>
            </div>
        </div>

        <div v-if="step === 2 || activation_step">
            <br>
            <label class="inp "
                   :class="{error:error.activate_code}">
                <p class="inpName">Код</p>
                <input type="text"
                       v-mask="X"
                       v-model="activate_code_param"
                       placeholder="Код">
                <span class="err">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17"><path fill="none" fill-rule="nonzero" stroke="#E45C52" d="M8.662 8.662l-.177-.177-8.131 8.132h.707l-.707-.707v.707l8.131-8.132L.354.354v.707L1.06.354H.354l8.131 8.131L16.617.354h-.707l.707.707V.354L8.485 8.485l.177.177zm0 0l7.955 7.955v-.707l-.707.707h.707L8.662 8.662z"></path></svg>
                    </span>
                <p class="inpErr">{{ error.activate_code }}</p>
            </label>
            <label class="inp "
                   :class="{error:error.password}">
                <p class="inpName">Новый пароль</p>
                <input type="password"
                       v-model="password"
                       placeholder="Пароль">
                <span class="err">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17"><path fill="none" fill-rule="nonzero" stroke="#E45C52" d="M8.662 8.662l-.177-.177-8.131 8.132h.707l-.707-.707v.707l8.131-8.132L.354.354v.707L1.06.354H.354l8.131 8.131L16.617.354h-.707l.707.707V.354L8.485 8.485l.177.177zm0 0l7.955 7.955v-.707l-.707.707h.707L8.662 8.662z"></path></svg>
                    </span>
                <p class="inpErr">{{ error.password }}</p>
            </label>
            <div class="wrap-sendPass">
                <button type="submit"
                        @click="setPass"
                        class="create btn colorGreen btn360">
                    <span class="colorGreen-hover"></span>
                    <span class="zi">Сохранить</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import apiService   from '../../services/api';
    import errors       from '../../helpers/errors';
    import VueRecaptcha from 'vue-recaptcha';
    import flashMsg     from "../../services/flashMsg";

    export default {
        name: "restorePassword",
        data: function () {
            return {
                render: true,
                restoreIsEmail: true,
                recaptchaSiteKey: recaptchaSiteKey,
                email: '',
                phone: '',
                step: 1,
                password: '',
                activate_code: '',
                error: {
                    email: '',
                    captcha: '',
                    activate_code: ''
                },
                captcha: false
            };
        },
        watch: {
            email: function () {
                this.error.email = '';
            }
        },
        components: {
            'vue-recaptcha': VueRecaptcha
        },
        computed: {
            activate_code_param: {
                get: function () {
                    if (this.activate_code) return this.activate_code;
                    return getParameterByName('code') ? getParameterByName('code') : '';
                },
                set: function (value) {
                    this.activate_code = value;
                    return value;
                }
            },
            activation_step: function () {
                return getParameterByName('code') ? true : false;
            }
        },
        directives: {},
        mounted() {
        },
        methods: {
            send: function () {
                var _this = this;

                if (this.validate()) {
                    if (this.restoreIsEmail) {
                        apiService.send('forgot-password', {
                            email: this.email
                        }).then(function (data) {
                            _this.step = 2;
                        }).catch(function (err) {
                            if (err.hasOwnProperty('responseJSON')) {
                                _this.error.email = errors.login;
                            }
                        });
                    } else {
                        apiService.send('get-restore-code-phone', {
                            phone: this.phone
                        }).then(function (data) {
                            _this.step = 2;
                        }).catch(function (err) {
                            if (err.hasOwnProperty('responseJSON') && err.responseJSON.hasOwnProperty('error')) {
                                flashMsg.add(err.responseJSON.error, 'error');
                            }
                        });
                    }
                }
            },
            setPass: function () {
                var _this = this;

                if (this.validateSecondStep()) {
                    if (!this.restoreIsEmail) {
                        apiService.send('check-restore-code-phone', {
                            code: this.activate_code_param,
                        }).then(function (data) {
                            if (data.hasOwnProperty('code')) {
                                apiService.send('reset-password', {
                                    code: data.code,
                                    password: _this.password
                                }).then(function (data) {
                                    window.location.href = '/login';
                                }).catch(function (err) {
                                    if (err.hasOwnProperty('responseJSON')) {
                                        _this.error.activate_code = errors.activate_code;
                                    }
                                });
                            }
                        }).catch(function (err) {
                            if (err.hasOwnProperty('responseJSON')) {
                                _this.error.activate_code = errors.activate_code;
                            }
                        });
                    } else {
                        apiService.send('reset-password', {
                            code: this.activate_code_param,
                            password: this.password
                        }).then(function (data) {
                            window.location.href = '/login';
                        }).catch(function (err) {
                            if (err.hasOwnProperty('responseJSON')) {
                                _this.error.activate_code = errors.activate_code;
                            }
                        });
                    }
                }
            },
            validate: function () {
                var error = false;
                if (this.restoreIsEmail) {
                    if (!this.email) {
                        this.error.email = errors.empty;
                        error = true;
                    } else {
                        if (!validateEmail(this.email)) {
                            this.error.email = errors.email;
                            error = true;
                        }
                    }
                } else {
                    if (!this.phone) {
                        this.error.phone = errors.empty;
                        error = true;
                    }
                }

                if (!this.captcha) {
                    error = true;
                    this.error.captcha = errors.captcha;
                }

                return !error;
            },
            validateSecondStep: function () {
                var error = false;
                if (!this.activate_code_param) {
                    this.error.activate_code = errors.empty;
                    error = true;
                }

                if (!this.password) {
                    error = true;
                    this.error.password = errors.empty;
                }

                return !error;
            },
            onSubmit: function () {
                this.$refs.invisibleRecaptcha.execute();
            },
            onVerify: function (response) {
                this.captcha = true;
                this.error.captcha = '';
            },
            onExpired: function () {
                console.log('Expired');
                this.resetRecaptcha();
            },
            resetRecaptcha() {
                this.$refs.recaptcha1.reset(); // Direct call reset method
            }
        }
    };
</script>

<style scoped>

</style>
