export default {
    'uploadTmp':    {
        type: 'POST',
        url:  '/api/uploadTmp'
    },
    'removeTmp':    {
        type: 'POST',
        url:  '/api/removeTmp'
    },
    'createTender': {
        type: 'POST',
        url:  '/api/create_tender'
    },
    'calls':        {
        type: 'POST',
        url:  '/api/calls'
    },
    'my-tenders':        {
        type: 'POST',
        url:  '/api/my-tenders'
    },
    'my-tenders-performer':        {
        type: 'POST',
        url:  '/api/my-tenders-performer'
    },
    'changeTenderStatus':        {
        type: 'POST',
        url:  '/api/change-tender-status'
    },
    'tenders':        {
        type: 'POST',
        url:  '/api/tenders'
    },
};
