<template>
    <div class="createTend-tabsContent">
        <div class="">
            <div class="recover mrb24">

                <div class="mrb24 jc-start">
                    <h2 class="darkBlue">{{ name }}</h2>
                </div>

                <div class="col-v10 mrb24">
                    <p v-if="getIndustryName"
                       class="grey">{{ getIndustryName }}{{ getSubIndustryName ? ' / ' + getSubIndustryName : '' }}</p>
                </div>
                <div class="col-v10 mrb24">
                    <div
                        ref="description"
                        class="ellipsisHidden"
                        :class="{open:showAllDescription===1}">
                        <nl2br :class="{full:showAllDescription===1}"
                               tag="p"
                               :text="description"/>
                        <!-- выводится три строчки и третья оканчивается троеточием -->
                    </div>
                </div>

                <div v-if="siteUrl"
                     class="col-v10 sp-btw mrb24 siteUrl">
                    <div class="mob">
                        <div v-if="showAllDescription===0"
                             @click="showAllDescription=1"
                             class="rb green ellipsisHidden-open">Читать полностью
                        </div>
                        <div v-if="showAllDescription===1"
                             @click="showAllDescription=0"
                             class="rb green ellipsisHidden-open">Свернуть
                        </div>
                    </div>

                    <div class="mob" v-if="siteUrl">
                        <div class="dib">Портал проведения тендера</div>
                        <a :href="siteUrl"
                           target="_blank"
                           class="mrl10 green rb">{{ getHostTender }}</a>
                    </div>
                </div>

                <div class="row-wrap">
                    <div class="col-v6">
                        <div class="mrb24"
                             v-for="file in files">
                            <div><a target="_blank"
                                    :href="file.path"><span class="ion-paperclip mrr10"></span>{{file.name}}</a></div>
                        </div>
                    </div>
                </div>


                <div class="recover bgPdt sp-btw mrb45 budget">
                    <div class="mob mrb16">
                        <div class="inpName grey">Бюджет
                            <span class="blackLight mrl30">
                                <span>{{min_price}}</span> <span>{{currency.label}}</span>
                            </span>
                        </div>
                    </div>

                </div>

                <div class="col-v10 sp-btw boxDate">
                    <div class="grey">Заявки принимаются до {{start_date|moment('DD.MM.YYYY')}}</div>
                    <div class="grey">Дата окончания тендера <span class="mrl10 blackLight grey">{{ end_date|moment('DD.MM.YYYY') }}</span></div>
                </div>

            </div>


            <div class="recover mrb24">
                <div class="mrb24 jc-start">
                    <h2 class="darkBlue">Условия</h2>
                </div>
                <div class="col-v10 сonditMrb">
                    <div v-if="allow_view === 0 && performer_type === 0">
                        <span class="ion-record mrr10"></span>Любой участник
                    </div>
                    <div v-if="allow_view === 1">
                        <span class="ion-record mrr10"></span>Участник только со статусом<div class="tag mrl24">ATS</div>
                    </div>
                    <div v-if="allow_view === 2">
                        <span class="ion-record mrr10"></span>Только выбранные мной участники
                    </div>
                    <div v-if="performer_type === 1">
                        <span class="ion-record mrr10"></span>Участие только для физических лиц
                    </div>
                    <div v-if="performer_type === 2">
                        <span class="ion-record mrr10"></span>Участие только для юридических лиц
                    </div>
                </div>
            </div>
            <div class="recover mrb24" v-if="skills">
                <div class="mrb24 jc-start">
                    <h2 class="darkBlue">Необходимые навыки</h2>
                </div>
                <div class="col-v10 сonditMrb">
                    <div>{{skills}}</div>
                </div>
            </div>
            <div class="recover mrb24" v-if="questions.length">
                <div class="mrb24 jc-start">
                    <h2 class="darkBlue">Вопросы к исполнителю</h2>
                </div>
                <div v-for="question in questions" class="col-v10 сonditMrb">
                    <div><span class="ion-record mrr10"></span>{{question.text}}</div>
                </div>
            </div>

            <div class=" recover spBtw-alSt">
                <div class="col-v10 mrb45">
                    <h2 class="darkBlue">Организатор</h2>
                </div>
                <div class="col-84 jc-st-al-st pr0">
                    <div class="prfCard-img ">
                        <img :src="person.avatar" title="" alt="">
                        <span class="ion-record online">Сейчас на сайте</span>
                    </div>
                    <div class="usCrdInf ">
                        <div class="usCrdInf-top">
                            <div class="mrb24 sp-btw">
                                <div class="darkBlue rb">
                                    {{ userData.legal ? userData.companyName : userData.firstname + ' ' + userData.surname.substr(0, 1).toUpperCase() + '.' }}
                                    <div v-if="userData.isPremium" class="tag">Gold</div>
                                </div>
                            </div>
                            <div class="grey mrb24">{{userData.legal ? 'Юридическое лицо' : 'Физическое лицо' }}</div>
                            <div class="col-v10 mrb8">
                                <div class="ellipsisHidden h47"
                                     :class="{open:showAllCompanyDescription===1}"
                                     ref="companyDescription">
                                    <nl2br tag="p"
                                           :class="{full:showAllCompanyDescription}"
                                           :text="person.company.about"/>
                                    <!-- две строки, вконце троеточие -->
                                </div>
                            </div>
                            <div class="col-v10 jc-end ">
                                <div v-if="showAllCompanyDescription===0"
                                     @click="showAllCompanyDescription=1"
                                     class="rb green ellipsisHidden-open">Читать полностью
                                </div>
                                <div v-if="showAllCompanyDescription===1"
                                     @click="showAllCompanyDescription=0"
                                     class="rb green ellipsisHidden-open">Свернуть
                                </div>
                            </div>
                        </div>
                        <div class="hr mrb45 width"></div>
                        <div class="usCrdInf-btm row-wrap">
                            <div class="mrb24 col-v10"
                                 v-if="timeRegion">
                                <div class="pl48"><span class="ion-location"></span>({{timeRegion}})</div>
                            </div>

                            <div class="mrb24 col-v10 mrb24"><a
                                class="pl48"><span class="ion-a-mail"></span>{{contactEmail}}</a></div>
                            <div class="mrb24 col-v10"
                                 v-if="userData.site">
                                <a :href="userSiteUrl"
                                   target="_blank"
                                   class="pl48 green break-word col-v10"><span class="ic-site ic"></span>{{userData.site}}</a>
                            </div>
                            <div class=" col-v10"><a
                                class="pl48"><span class="ion-a-call"></span>{{contactPhone}}</a></div>
                        </div>
                    </div>
                </div>

                <div class="positionRating pr0 tx-rig">
                    <div class="topInf-rig">
                        <div class="grey mrb8">Рейтинг <span class="reit-num rm">{{ person.rating }}</span></div>
                        <div class="grey">Отзывов <span class="amber rm">6</span></div>
                    </div>
                </div>
            </div>
        </div>
        <!--  Тендер отправлен на модерацию  + -->

        <div class="modal" :class="{open:created && paymentModal}" id="createTender-modal">
            <div class="boardM">
                <div class="board-title">
                    <p class="rm">Тендер отправлен на модерацию</p>
                    <span class="ion-a-close" data-modal-close></span>
                </div>
                <div class="board-inf">
                    <div class="col-v10 cnt mrb96">
                        <p>Как правило модерация занимает до 12 часов.<br>
                            Результаты модерации вы увидите в своем профиле, а также уведомлением на почту</p>
                    </div>
                    <div class="col-v10 cnt">
                        <a href="/person"
                           class="btn colorGreen btn217">
                            <span class="colorGreen-hover"></span>
                            <span class="zi">В профиль</span>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import store from '../../../../store';
    import {
        mapMutations,
        mapActions,
        mapGetters,
        mapState
    } from 'vuex';
    import VueSelect from 'vue-select';
    import datepicker from 'vuejs-datepicker';
    import VueTimepicker from '../../../utils/vue-timepicker';
    import Nl2br from 'vue-nl2br';
    import totals from '../optionService';

    export default {
        name: "step1",
        store,
        data() {
            return {
                ru: window.ru,
                uploadFiles: [],
                showAllDescription: 0,
                showAllCompanyDescription: 0,
                person: personData,
                timer: null,
                timeRegion: '',
            };
        },
        props: {
            created: {
                type: Boolean,
                default: false
            },
            paymentModal: {
                type: Boolean,
                default: null
            },
        },
        computed: {
            getHostTender() {
                let tmp = document.createElement('a');
                tmp.href = this.tender_portal;
                console.log(tmp);
                return tmp.origin;
            },
            totalPrice() {
                return this.$store.getters['optionService/totalPrice'];
            },
            currency() {
                let currency = this.$store.state.options.currencies.find(e => e.id === this.currency_id);
                return currency ? currency : {
                    label: ''
                };
            },
            country() {
                let country = this.$store.state.geo.countries.find(e => e.id === this.$store.state.tender.userData.country_id);
                return country ? country : {
                    id: null,
                    label: 'Cтрана'
                };
            },
            city() {
                let city = this.$store.state.geo.cities.find(e => e.id === this.$store.state.tender.userData.city_id);
                return city ? city : {
                    id: null,
                    label: 'Город'
                };
            },
            siteUrl() {
                return this.$store.getters['tender/getSiteUrl'];
            },
            contactEmail() {
                return this.$store.getters['tender/getContactEmail'];
            },
            contactPhone() {
                return this.$store.getters['tender/getContactPhone'];
            },
            userSiteUrl() {
                return this.$store.getters['tender/getUserSiteUrl'];
            },
            getIndustryName() {
                let industry = this.$store.state.industry.industries.find(e => e.id === this.industry);
                return industry ? industry.label : null;
            },
            getSubIndustryName() {
                let name       = null,
                    industries = this.$store.state.industry.sub_industries;

                if (industries.hasOwnProperty(this.industry)) {
                    let industry = industries[this.industry].find(e => e.id === this.sub_industry);
                    if (industry.hasOwnProperty('label')) {
                        name = industry.label;
                    }
                }

                return name;
            }, ...mapState('tender', [
                'name',
                'description',
                'max_price',
                'min_price',
                'currency_id',
                'tender_portal',
                'start_date',
                'end_date',
                'service_id',
                'quickly',
                'quickly_time',
                'safely',
                'insurance',
                'performer_type',
                'country_id',
                'city_id',
                'allow_view',
                'only_legal',
                'skills',
                'industry',
                'sub_industry',
                'files',
                'userData',
            ]),
            ...mapGetters('tender', ['questions',]),
            ...mapState('optionService', [
                'services',
                'checked'
            ]),
            ...mapGetters('optionService', ['totalPrice'])
        },
        methods: {
            ...mapActions('optionService', [
                'setOptionServices'
            ]), ...mapMutations('optionService', [
                'changeChecked'
            ]),
            descriptionSizeCheck(ref, value) {
                let el = this.$refs[ref];
                this.$log.debug($(el));
                if (el.offsetHeight > el.children[0].offsetHeight) this[value] = 2;
            },
            getTimeZone(event) {
                if (!event.id) return;
                window.geoService.getTimeZone(event.id, e => {
                    clearInterval(this.timer);
                    this.setRegionTime(e);
                    this.timer = setInterval(() => {
                        this.setRegionTime(e);
                    }, 10000);
                });
            },
            setRegionTime(e) {
                let time = this.$moment().utcOffset(e.timeZone.utc).format('H:mm');
                this.timeRegion = this.city.label + ' ' + e.region.name + ', Местное время ' + time;
            }
        },
        components: {
            'v-select': VueSelect,
            'datepicker': datepicker,
            VueTimepicker,
            Nl2br,
            totals: totals,
        },
        mounted() {

            this.setOptionServices();
            this.$store.dispatch('industry/getIndustries');
            this.descriptionSizeCheck('description', 'showAllDescription');
            this.descriptionSizeCheck('companyDescription', 'showAllCompanyDescription');
            this.$store.dispatch('geo/getCountries');
            this.$store.dispatch('geo/getCities');
            this.getTimeZone(this.city);

            if (this.paymentModal) {
                // тут открывай свою модалку если нужно

            }
        }
    };
</script>

<style scoped>

</style>
