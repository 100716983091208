import errors       from '../../helpers/errors';
import Datepicker   from 'vuejs-datepicker';
import VueRecaptcha from 'vue-recaptcha';
import vSelect      from 'vue-select';
import apiService   from '../../services/api';
import geoService   from '../../services/geo';
import Vue          from "vue";
import flash        from "../../services/flashMsg";
import isUrl        from "validator/lib/isURL";

export default function () {
    return new Vue({
        el: '#vue-person-app',
        data() {
            return {
                stateCompanyDob: {
                    from: null
                },
                isDisplayConfirmedCodePhone: false,
                isDisplayModalConfirmed: false,
                confirmedCodePhone: '',
                ...personData,
            }
        },
        watch: {
            email: function () {
                this.error.email = '';
            },
            'company.director.firstname': function () {
                this.error.company.director.firstname = '';
            },
            'company.director.surname': function () {
                this.error.company.director.surname = '';
            },
            'company.director.patronymic': function () {
                this.error.company.director.patronymic = '';
            },
            phone: function () {
                this.error.phone = '';
            },
            password: function () {
                this.error.password = '';
            },
            password_confirmation: function () {
                this.error.password_confirmation = '';
            },
            'company.country': function () {
                if (this.company.country.id) {
                    geoService.getCities(this.company.country.id);
                }
            },
            is_customer: function () {
                let event = new Event('person_type');
                event.types = {
                    is_performer: this.is_performer,
                    is_customer: this.is_customer
                };
                window.dispatchEvent(event);

                apiService.send('user-update', {
                    is_customer: this.is_customer ? 1 : 0
                }).then(function (data) {

                }).catch(function (err) {
                    if (err.hasOwnProperty('responseJSON')) {
                        console.error(err.responseJSON.error);
                    }
                });
            },
            is_performer: function () {
                let event = new Event('person_type');
                event.types = {
                    is_performer: this.is_performer,
                    is_customer: this.is_customer
                };
                window.dispatchEvent(event);

                apiService.send('user-update', {
                    is_performer: this.is_performer ? 1 : 0
                }).then(function (data) {

                }).catch(function (err) {
                    if (err.hasOwnProperty('responseJSON')) {
                        console.error(err.responseJSON.error);
                    }
                });
            }

        },
        components: {
            'vue-recaptcha': VueRecaptcha,
            'v-select': vSelect,
            'datepicker': Datepicker
        },
        computed: {
            legal: {
                get: function () {
                    return this.default.legal;
                },
                set: function (value) {
                    this.default.legal = parseInt(value);
                }
            },
            dobNormal: function () {
                let dob = this.legal ? this.directorDob : this.dob;
                if (dob) {
                    return dob.toLocaleString().split(',')[0];
                } else {
                    return '';
                }
            },
            companyEdit: function () {
                let isCheck = !!this.site || !!this.company.about;
                if (!isCheck) {
                    if (this.legal) {
                        isCheck = !!this.company.edit || !!this.name || !!this.company.tender_exp || !!this.company.tenderStaff || !!this.company.director.surname || !!this.company.director.firstname || !!this.company.director.patronymic || !!this.directorDob;
                    } else {
                        isCheck = !!this.dob;
                    }
                }

                return isCheck;
            },
            portfolioEdit: function () {
                return !!portfolio.length;
            },
            specializationsShow: function () {
                return this.specializations.filter(function (e) {
                    return !!e.checked;
                });

            },
            reviewsShow: function () {
                return this.reviews.length;
            },
            contactsEdit: function () {
                if (this.edit.contacts) return true;

                return this.contacts.length;
            },
            specializationEdit: function () {
                if (this.edit.specialization) return true;

                let complete = false;

                this.specializations.forEach(function (e) {
                    if (e.checked) {
                        complete = true;
                        return false;
                    }
                });

                return complete;
            },
            personal_complete: function () {
                return this.isFillAllFields && (!this.is_performer || this.portfolioEdit) && (this.specializations.filter(e => e.checked === true).length || !this.is_performer)
                    && this.companyEdit && this.is_activated && this.is_activated_phone && this.isFillAllInfo && (!this.is_performer || this.is_verified_performer);
            },
            isFillAllInfo: function () {
                let isFill = !!this.site && !!this.company.country.id && !!this.company.city.id && !!this.company.about;
                if (isFill) {
                    if (this.legal) {
                        isFill = !!this.company.name && !!this.company.tender_exp && !!this.company.director.surname &&
                            !!this.company.director.firstname && !!this.company.director.patronymic && !!this.company.tenderStaff && !!this.directorDob;
                    } else {
                        isFill = !!this.dob;
                    }
                }
                return isFill;
            }
        },
        directives: {},
        created() {
            let minDate = Vue.moment().subtract(18, 'years').toDate();
            this.stateCompanyDob.from = minDate;
        },
        mounted() {
            let el = this;

            window.addEventListener('isPerformerChange', function (e) {
                el.is_performer = e.detail;
                el.changeIsPerformer();
            });
        },
        methods: {
            changeSite: function (value) {
                let error = '';
                if (value) {
                    if (!isUrl(value)) {
                        error = 'невалидный адрес';
                    } else if (!value.includes('http')) {
                        value = 'http://' + value;
                    }
                }
                this.error.site = error;
                this.site = value;
            },
            getConfirmedCodePhone: function () {
                let self = this;
                if (!self.ajax.phoneActivated) {
                    self.ajax.phoneActivated = true;
                    apiService.send('get-activation-code', {
                        phone: self.phone
                    }).then(function (data) {
                        self.isDisplayConfirmedCodePhone = true;
                        self.isDisplayModalConfirmed = false;
                    }).catch(function (err) {
                        if (err.hasOwnProperty('responseJSON') && err.responseJSON.hasOwnProperty('error')) {
                            flash.add(err.responseJSON.error, 'error');
                        }
                    }).finally(function () {
                        self.ajax.phoneActivated = false;
                    });
                }
            },
            sendConfirmedCodePhone: function () {
                let self = this;
                apiService.send('confirm-activation-code', {
                    code: self.confirmedCodePhone,
                    phone: self.phone
                }).then(function (data) {
                    if (data.hasOwnProperty('success')) {
                        console.log('success');
                        self.is_activated_phone = 1;
                    }
                }).catch(function (err) {
                    if (err.hasOwnProperty('responseJSON') && err.responseJSON.hasOwnProperty('error')) {
                        flash.add(err.responseJSON.error, 'error');
                    }
                });
            },
            addContact: function () {
                this.contacts.push({
                    'firstname': '',
                    'surname': '',
                    'patronymic': '',
                    'phone': '',
                    'email': '',
                    'error': {
                        'firstname': '',
                        'surname': '',
                        'patronymic': '',
                        'phone': '',
                        'email': ''
                    }
                });
            },
            attachFile: function (event, type) {
                if (event.target.files[0].size > 20 * 1024 * 1024) {
                    flash.add('Файл не может превышать 20МБ', 'error');
                } else {
                    if (Array.isArray(this.company.files[type])) {
                        this.company.files[type].push(event.target.files[0]);
                    } else {
                        this.company.files[type] = event.target.files[0];
                    }
                }
            },
            removeFile: function (type, key) {
                if (Array.isArray(this.company.files[type])) {
                    this.company.files[type].splice(key, 1);
                } else {
                    this.company.files[type] = null;
                }
            },
            removeContact: function (key) {
                this.contacts.splice(key, 1);
            },
            editBlock: function (block, status) {
                this.edit[block] = status ? status : false;

                if (block === 'company' && status) {
                    $(this.$refs['select_countries']).trigger('refresh');
                    $(this.$refs['select_cities']).trigger('refresh');
                    // $(this.$refs['select_countries']).styler({
                    //     selectPlaceholder: 'Выбрать страну',
                    // });
                    $(this.$refs['select_cities']).styler({
                        selectPlaceholder: 'Выбрать город'
                    });
                }
            },
            updateAbout: function () {
                let _this = this;

                if (!this.ajax.about && this.validateAbout()) {
                    this.ajax.about = true;
                    _this.isDisplayConfirmedCodePhone = false;

                    apiService.send('user-update', {
                        email: this.email,
                        phone: this.phone.replace(/[^0-9.]/g, ""),
                        name: this.firstname,
                        surname: this.surname
                    }).then(function (data) {
                        if (data.hasOwnProperty('isFillAllFields')) {
                            _this.isFillAllFields = data.isFillAllFields;
                        }
                        if (data.hasOwnProperty('rating')) {
                            _this.rating.customer = data.rating.customer;
                            _this.rating.performer = data.rating.performer;
                        }
                        if (data.hasOwnProperty('changePhone') && data.changePhone === true) {
                            _this.is_activated_phone = false;
                        }
                        _this.editBlock('about');
                        _this.ajax.about = false;
                    }).catch(function (err) {
                        _this.ajax.about = false;
                        if (err.hasOwnProperty('responseJSON')) {
                            flash.add(err.responseJSON.error, 'error');
                        }
                    });
                }
            },
            updateContacts: function () {
                if (!this.ajax.contacts && this.validateContacts()) {
                    this.ajax.contacts = true;

                    let fd = new FormData,
                        _this = this;
                    this.contacts.forEach(function (e, i) {
                        if (typeof e.id !== "undefined") fd.append('contact[' + i + '][id]', e.id);

                        fd.append('contact[' + i + '][firstname]', e.firstname);
                        fd.append('contact[' + i + '][patronymic]', e.patronymic);
                        fd.append('contact[' + i + '][surname]', e.surname);
                        fd.append('contact[' + i + '][email]', e.email);
                        fd.append('contact[' + i + '][phone]', e.phone.replace(/[^0-9.]/g, ""));
                    });
                    apiService.send('contacts-update', fd).then(function (data) {
                        if (data.hasOwnProperty('isFillAllFields')) {
                            _this.isFillAllFields = data.isFillAllFields;
                        }
                        if (data.hasOwnProperty('rating')) {
                            _this.rating.customer = data.rating.customer;
                            _this.rating.performer = data.rating.performer;
                        }
                        _this.editBlock('contacts');
                        _this.contacts = data.contacts;
                        _this.ajax.contacts = false;
                    }).catch(function (err) {
                        // console.error(err);
                        _this.ajax.contacts = false;
                    });
                }
            },
            updateSpecialization: function () {
                let _this = this;

                if (!this.ajax.specialization && this.validateSpecialization()) {
                    this.ajax.specialization = true;

                    let fd = new FormData,
                        k = 0;
                    this.specializations.forEach(function (e) {
                        if (e.checked) {
                            fd.append('spec[' + k + '][type_id]', e.type);
                            fd.append('spec[' + k + '][min_price]', e.min_price);
                            fd.append('spec[' + k + '][max_price]', e.max_price);
                            fd.append('spec[' + k + '][currency_id]', e.currency.id);
                            k++;
                        }
                    });
                    apiService.send('specialization-update', fd).then(function (data) {
                        _this.editBlock('specialization');
                        _this.ajax.specialization = false;
                    }).catch(function (err) {
                        _this.ajax.specialization = false;
                        if (err.hasOwnProperty('responseJSON')) {
                            _this.error.email = err.responseJSON.error;
                            $.oc.flashMsg({text: err.responseJSON.error, class: 'error'});
                        }
                    });
                }
            },
            updateCompany: function () {
                let _this = this;

                if (!_this.ajax.company && this.validateCompany()) {
                    _this.ajax.company = true;

                    let formData = new FormData();

                    formData.append('legal', this.legal);
                    formData.append('name_company', this.company.name);
                    formData.append('site', this.site);
                    formData.append('about', this.company.about);
                    formData.append('tender_exp', this.company.tender_exp);
                    formData.append('tender_staff', this.company.tenderStaff);

                    if (this.legal) {
                        formData.append('firstname', this.company.director.firstname);
                        formData.append('surname', this.company.director.surname);
                        formData.append('patronymic', this.company.director.patronymic);

                        if (this.company.files.booker) formData.append('file_booker', this.company.files.booker);
                        if (this.company.files.charter) formData.append('file_charter', this.company.files.charter);
                        if (this.company.files.ogrn) formData.append('file_ogrn', this.company.files.ogrn);
                        if (this.company.files.file) formData.append('file_file', this.company.files.file);
                    } else {
                        formData.append('firstname', this.firstname);
                        formData.append('surname', this.surname);

                        if (this.company.files.docs && this.company.files.docs.length) {
                            this.company.files.docs.forEach(function (e) {
                                formData.append('docs[]', e);
                            });
                        }
                    }

                    if (typeof this.company.country.id !== "undefined") formData.append('country', this.company.country.id);
                    if (typeof this.company.city.id !== "undefined") formData.append('city', this.company.city.id);
                    if (this.dob) formData.append('dob', this.dob.toMysqlFormat(true));
                    if (this.directorDob) formData.append('legal.dob', this.directorDob.toMysqlFormat(true));

                    apiService.send('company-update', formData).then(function (data) {
                        if (data.hasOwnProperty('isFillAllFields')) {
                            _this.isFillAllFields = data.isFillAllFields;
                        }
                        if (data.hasOwnProperty('rating')) {
                            _this.rating.customer = data.rating.customer;
                            _this.rating.performer = data.rating.performer;
                        }
                        _this.ajax.company = false;
                        _this.editBlock('company');
                        if (data.hasOwnProperty('files')) {
                            let keys = Object.keys(data.files);
                            for (let i = 0; i < keys.length; i++) {
                                let file = _this.company.files[keys[i]],
                                    e = data.files[keys[i]];
                                if (Array.isArray(file)) {
                                    file.map(function (f, i) {
                                        f.path = e[i];
                                    });
                                } else {
                                    file.path = e;
                                }
                            }
                        }
                        let name = _this.legal && (_this.company.director.surname || _this.company.director.firstname)
                            ? _this.company.director.surname + ' ' + _this.company.director.firstname
                            : _this.surname + ' ' + _this.firstname;
                        $('#header-name').text(name);
                    }).catch(function (err) {
                        _this.ajax.company = false;
                        if (err.hasOwnProperty('responseJSON')) {
                            // _this.error.email = err.responseJSON.error;
                            flash.add(err.responseJSON.error, 'error');
                        }
                    });
                }
            },
            validate: function () {
                let error = false;
                if (!this.password) {
                    this.error.password = errors.empty;
                    error = true;
                }

                if (!this.password_confirmation) {
                    this.error.password_confirmation = errors.empty;
                    error = true;
                }

                if (this.password !== this.password_confirmation) {
                    this.error.password_confirmation = errors.password_confirmation;
                    error = true;
                }

                return !error;
            },
            validateSpecialization: function () {
                let error = false;

                return !error;
            },
            validateContacts: function () {
                let error = false;

                this.contacts.forEach(function (e) {
                    if (!e.email) {
                        e.error.email = errors.empty;
                        error = true;
                    } else {
                        if (!validateEmail(e.email)) {
                            e.error.email = errors.email;
                            error = true;
                        }
                    }

                    if (!e.firstname) {
                        e.error.firstname = errors.empty;
                    }

                    if (!e.patronymic) {
                        e.error.patronymic = errors.empty;
                    }
                });

                return !error;
            },
            validateAbout: function () {
                let error = false;
                if (!this.email) {
                    this.error.email = errors.empty;
                    error = true;
                } else {
                    if (!validateEmail(this.email)) {
                        this.error.email = errors.email;
                        error = true;
                    }
                }

                if (!this.phone) {
                    // error            = true;
                    // this.error.phone = errors.empty;
                } else {
                    if (this.phone.replace(/[^0-9.]/g, "").length < 11) {
                        error = true;
                        this.error.phone = errors.phone;
                    }
                }

                return !error;
            },
            validateCompany: function () {
                let error = false;

                if (this.legal) {
                    if (!this.company.director.firstname) {
                        this.error.company.director.firstname = errors.require;
                        error = true;
                    }

                    if (!this.company.director.surname) {
                        this.error.company.director.surname = errors.require;
                        error = true;
                    }

                    if (!this.company.director.patronymic) {
                        this.error.company.director.patronymic = errors.require;
                        error = true;
                    }
                }

                return !error;
            },
            changeIsCustomer: function () {
                let buttonCreateTender = $('#header-create-tender');
                if (this.is_customer) {
                    buttonCreateTender.removeClass('hidden');
                } else {
                    buttonCreateTender.addClass('hidden');
                }
            }
        }
    });
}
