import errors     from '../../helpers/errors';
import apiService from '../../services/api';
import Vue        from "vue";

export default function () {

    return new Vue({
        el:         '#vue-login-app',
        data:       function () {
            return {
                email:    '',
                password: '',
                error:    {
                    email:    '',
                    password: '',
                },
                type:     {
                    password: 'password'
                }
            };
        },
        watch:      {
            email:    function () {
                this.error.email = '';
            },
            password: function () {
                this.error.password = '';
            },
        },
        components: {},
        computed:   {},
        directives: {},
        mounted() {
        },
        methods:    {
            switchVisibility(type) {
                this.type[type] = this.type[type] === 'password' ? 'text' : 'password';
                this.$refs[type].focus();
            },
            send:     function () {
                let _this = this;

                if (this.validate()) {
                    apiService.send('auth', {
                        email:    this.email,
                        password: this.password,
                    }).then(function (data) {
                        apiService.setApiToken(data.token);
                        window.location.href = '/person';
                    }).catch(function (err) {
                        if (err.hasOwnProperty('responseJSON')) {
                            _this.error.email = errors.login;
                        }
                    });
                }
            },
            validate: function () {
                let error = false;
                if (!this.email) {
                    this.error.email = errors.empty;
                    error            = true;
                }
                else {
                    if (!validateEmail(this.email)) {
                        this.error.email = errors.email;
                        error            = true;
                    }
                }

                if (!this.password) {
                    this.error.password = errors.empty;
                    error               = true;
                }

                return !error;
            },
        }
    });
}
