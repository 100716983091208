<template>
    <div class="tender">
        <a :href="tender.url"
           class="linkAuto"></a>
        <div class="tender-top">
            <div class="wrap-topInf">
                <div class="topInf">
                    <div class="topInf-lef">
                        <h3 class="darkBlue">{{tender.name}}</h3>
                        <div class="wrap-tag">
                            <div class="tag-scroll">
                                <a v-if="tender.allow_view === 1"
                                   class="tag"><span class="colorGreen-hover"></span><span class="zi">только для ATS</span></a>
                                <a v-if="tender.performer_type === 2"
                                   class="tag"><span class="colorGreen-hover"></span><span class="zi">только для юр. лиц</span></a>
                            </div>
                        </div>
                        <div class="category grey" v-if="industry">
                            <span>{{industry.label}}</span><span v-if="sub_industry">, {{sub_industry.label}}</span>
                        </div>
                    </div>
                    <div class="topInf-price">
                        <div class="fs18 darkBlue"><span class="rb">{{tender.min_price}}</span> {{ currencyText }}</div>
                    </div>
                </div>
                <div class="botInf">
                    <div class="tender-description">
                        <nl2br tag="p" :text="tender.description"/>
                    </div>
                </div>
                <div class="col-v10 sp-btw">
                    <div class="grey pl40">
                        <div class="tender-icon-status">
                            <span :class="'ic ' + tenderStatusInfo.icon"></span>
                        </div>
                        <div class="tender-status-name">{{ tenderStatusInfo.name }}</div>
                    </div>
                    <div class="view grey">
                        <span class="ic view-ic viewRes-ic"></span>{{ total_views }}</div>
                    <div class="response grey">
                        <span class="ic response-ic viewRes-ic"></span>{{ total_offers}}</div>
                </div>
            </div>
        </div>
        <div class="tender-bot">
            <div class="bid">
                <div class="grey bidDate">заявки принимаются до:
                    <span class="mrr">{{start_date|moment('DD.MM.YYYY')}}</span>
                    <div v-if="tender.quickly_time"
                         class="bidTime red">до <span>{{quickly_time|moment('HH:mm')}}</span>
                        <span class="after ion-a-stopwatch"></span>
                    </div>
                </div>
            </div>
            <div class="release"
                 v-if="published">
                <div class="grey">опубликован: <span>{{published|moment('DD.MM.YYYY')}}</span></div>
            </div>
        </div>
    </div>
</template>

<script>
    import Nl2br  from 'vue-nl2br';
    import api    from '../../services/api';
    import prupal from "../../helpers/prupal";

    export default {
        name:       "tender",
        props:      {
            tender: Object,
            onlyView: {
                type: Boolean,
                default: true
            }
        },
        components: {
            Nl2br,
        },
        computed:   {
            quickly_time() {
                return this.tender.quickly_time ? this.$moment(this.tender.quickly_time, "HH:mm:ss") : null;
            },
            start_date() {
                return this.$moment(this.tender.start_date, "YYYY-MM-DD HH:mm:ss");
            },
            published() {
                return this.tender.published_at ? this.$moment(this.tender.published_at, "YYYY-MM-DD HH:mm:ss") : null;
            },
            end_date() {
                return this.$moment(this.tender.end_date, "YYYY-MM-DD HH:mm:ss");
            },
            tenderStatusInfo() {
                let statusInfo = this.tender.status_info;
                return statusInfo !== undefined ? statusInfo : [];
            },
            checkChangeStatus() {
                return [
                    1,
                    2
                ].indexOf(this.tender.status) > -1;
            },
            currency() {
                let currency = this.$store.state.options.currencies.find(e => e.id === this.tender.currency_id);
                return currency ? currency : {label: ''};
            },
            industry() {
                return !this.tender.hasOwnProperty('industry') || !this.$store.state.industry.industries.length? null : this.$store.state.industry.industries.find(e => e.id === this.tender.industry);
            },
            sub_industry() {
                return !this.tender.hasOwnProperty('sub_industry') || !this.industry ? null : this.$store.getters['industry/getSubIndustries'](this.industry.id).find(e => e.id === this.tender.sub_industry);
            },
            total_views() {
                return prupal(this.tender.total_views, ['просмотр', 'просмотра', 'просмотров']);
            },
            total_offers() {
                return prupal(this.tender.count_feedback, ['отклик', 'отклика', 'откликов']);
            },
            currencyText() {
                return prupal(this.tender.min_price, ['рубль', 'рубля', 'рублей'], false);
            }
        },
        methods:    {
            changeStatus(status) {
                api.send('changeTenderStatus', {
                    id:     this.tender.id,
                    status: status
                }).then(data => {
                    if (data.hasOwnProperty('success')) this.tender.status = status;
                }).catch(err => this.$log.error(err));
            }
        }
    };
</script>

<style scoped>

</style>
