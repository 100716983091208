<template>
    <div>
        <div class="wrap-tender">
            <div v-for="tender in tenders">
                <tender :only-view="false"
                        :tender="tender"></tender>
            </div>
            <div v-if="tenders.length === 0">У вас нет тендеров</div>
        </div>
        <div class="col-v10"
             v-if="page<maxPage">
            <a href="#"
               @click.stop.prevent="moreTenders"
               class="brGreen btn w100">Показать больше</a>
        </div>
    </div>
</template>

<script>
    import tender from '../tender';
    import api    from '../../../services/api';
    import store  from '../../../store';

    export default {
        name:       "index",
        data() {
            return {
                tenders: [],
                page:    1,
                maxPage: 1,
                pageName: pageName
            };
        },
        store,
        components: {
            tender,
        },
        methods:    {
            moreTenders() {
                this.page++;
                this.getTenders();
            },
            getTenders() {
                let self = this;
                api.send('my-tenders', {page: this.page, pageName: this.pageName}).then(data => {
                    data.items.forEach(item => self.tenders.push(item));
                    // self.tenders = data.items;
                    self.maxPage = data.maxPage;
                }).catch(err => this.$log.error(err));
            }
        },
        computed:   {},
        mounted() {
            this.getTenders();
        }
    };
</script>

<style scoped>

</style>
