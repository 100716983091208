<template>
    <div>
        <div class="wrap-h2">
            <h2 class="udr">Портфолио</h2>
        </div>

        <div v-if="!portfolioEdit"
             class="board center">
            <div class="board-title">
                <p class="rm">Мое портфолио</p>
            </div>
            <div class="board-inf ">
                <div class="cmpInf-text">
                    <p class="grey rm">У вас нет ни одной работы в портфолио</p>
                </div>
                <div class="cmpInf-btn">
                    <a href=""
                       @click.stop.prevent="addWork"
                       class="add grey">
                        <span class="ion-plus"></span>
                        Добавить работу</a>
                </div>
            </div>
        </div>

        <div v-if="portfolioEdit"
             class="board myCompany">
            <div class="board-title">
                <p class="rm">Мое портфолио</p>
            </div>
            <div class="board-inf board-outer">
                <div v-for="(item,key,index) in portfolio" class="wrap-board-inner">
                    <div v-if="key===editKey"
                         class="board-inner row-wrap">
                        <div class="inp disabled"
                             :class="{error:item.error.customer}">
                            <p class="inpName">Заказчик (наименование компании)* </p>
                            <input type="text"
                                   placeholder="Заказчик (наименование компании)*"
                                   v-model="item.customer"
                            >
                            <span class="err ion-a-close"></span>
                            <p class="inpErr">{{ item.error.customer }}</p>
                        </div>
                        <div class="col-v10 mrb24 row-wrap">
                            <div class="col-v5 col-t10">
                                <div class="sel">
                                    <v-select v-model="item.specializationType"
                                              :options="specializationTypes"></v-select>
                                </div>
                            </div>
                            <div class="col-v5 hidden"></div>
                        </div>
                        <div class="col-v5 col-t10 mrb24">
                            <div class="sel ">
                                <v-select v-model="item.industry"
                                          @input="changeIndustry"
                                          :options="industries"></v-select>
                            </div>
                        </div>
                        <div class="col-v5 col-t10 mrb24">
                            <div class="sel ">
                                <v-select v-model="item.sub_industry"
                                          :disabled="!item.industry.id"
                                          :options="sub_industries[item.industry.id]"></v-select>
                            </div>
                        </div>

                        <div class="inp inpTextarea disabled"
                             :class="{error:item.error.description}">
                            <p class="inpName">Опишите работу</p>
                            <textarea
                                    cols="30"
                                    rows="10"
                                    placeholder="Опишите работу*"
                                    v-model="item.description"
                            ></textarea>
                            <span class="err ion-a-close"></span>
                            <p class="inpErr"></p>
                        </div>
                        <div class="row-wrap col-v10 mrb24">
                            <div class="col-v5 col-t10">
                                <div class="sel">
                                    <v-select v-model="item.status"
                                              :options="portfolioStatuses"></v-select>
                                </div>
                            </div>
                            <div class="col-v5">
                            </div>
                        </div>

                        <div class="col-v10 jc-start-nw">
                            <div class="inp inp-nameCompany disabled" :class="{error:item.error.price}">
                                <p class="inpName">{{ item.status.id === 1 ? "Сумма выигранного контракта" : "Сумма контракта" }}</p>
                                <input type="text"
                                       :placeholder="item.status.id === 1 ? 'Сумма выигранного контракта' : 'Сумма контракта'"
                                       v-mask="'#############################'"
                                       v-model="item.price">
                                <span class="err ion-a-close"></span>
                                <p class="inpErr">{{ item.error.price }}</p>
                            </div>
                            <div class="sel pr0 selPrice selCurs mrb24">
                                <v-select v-model="item.currency"
                                          :options="currencies"></v-select>
                            </div>
                        </div>
                        <div class="col-v10 row-wrap mrb45">
                            <div class="mrb24 col-v7 col-t10">
                                <p>Прикрепите файл с протоколом по тендеру, в случае если он проводился на закрытых площадках</p>
                                <p class="grey">Размер файла не более 20 мб</p>
                            </div>
                            <div class="col-v3 col-t4 col-m7 pr0">
                                <div class="attach downLoad jc-start">
                                    <input type="file"
                                           :id="'file'+key"
                                           @change="addFile($event,key)"
                                    >
                                    <label :for="'file'+key"
                                           class="attach-lab ion-a-attach grey">
                                        <span v-if="!item.certificate">Сертификат отличия</span>
                                        <a target="_blank"
                                           v-if="item.certificate&&item.certificate.path"
                                           :href="item.certificate.path">{{ item.certificate.name }}</a>
                                        <span v-if="item.certificate"
                                              @click.stop.prevent="removeFile(key)"
                                              class="ion-a-close"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="jc-end col-v10">
                            <a href=""
                               @click.stop.prevent="editKey=null"
                               class="green rm mrr25">Отмена</a>
                            <div @click="updatePortfolio"
                                 class="create btn colorGreen btn148">
                                <span class="colorGreen-hover"></span>
                                <span class="zi">Сохранить</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="key!==editKey"
                         class="board-inner row-wrap">
                        <div class="inpResult">
                            <p class="grey">Заказчик (наименование компании)</p>
                            <p class="inpName">{{ item.customer }}</p>
                        </div>
                        <div class="inpResult col-v5" v-if="item.specializationType.id">
                            <p class="grey">Специализация</p>
                            <p class="inpName">{{ item.specializationType.label }}</p>
                        </div>
                        <div class="inpResult col-v5" v-if="item.industry.id">
                            <p class="grey">Отрасль</p>
                            <p class="inpName">{{ item.industry.label }}</p>
                        </div>
                        <div class="inpResult work-inpName">
                            <p class="grey">Опишите работу</p>
                            <p class="inpName">{{ item.description }}</p>
                        </div>
                        <div class="inpResult" v-if="item.price">
                            <p class="grey">{{ item.status.id === 1 ? "Сумма выигранного контракта" : "Сумма контракта" }}</p>
                            <p class="inpName">{{ item.price }} <span v-if="item.currency.id">{{ item.currency.label }}</span></p>
                        </div>
                        <div class="inpResult">
                            <div class="wrap-ic sp-btw col-v10">
                                    <span class="ion-a-create"
                                          @click="editKey=key"></span>
                                <!--<span class="ion-a-delete"-->
                                <!--@click="removeWork(key)"></span>-->
                                <span class="grey delete rm" @click="removeWork(item.id, key)">Удалить </span>
                            </div>
                        </div>
                    </div>
                    <!--<div class="hr width"-->
                    <!--v-if="key+1!=portfolio.length"></div>-->
                </div>
                <div class="inpResult bord-top">
                    <a href=""
                       @click.stop.prevent="addWork"
                       class="add grey">
                        <span class="ion-plus"></span>
                        Добавить работу</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import errors     from '../../helpers/errors';
    import vSelect    from 'vue-select';
    import apiService from '../../services/api';

    export default {
        name: "portfolio",
        data() {
            return {
                render: true,
                edit: {
                    portfolio: false
                },
                ajax: {
                    portfolio: false
                },
                portfolioStatuses: portfolioStatuses,
                currencies: currencies,
                portfolio: portfolio,
                industries: industries,
                sub_industries: Object.assign({null: []}, sub_industries),
                editKey: null,
                specializationTypes: specializationTypes
            }
        },
        components: {
            'v-select': vSelect
        },
        computed: {
            portfolioEdit: function () {
                return !!this.portfolio.length;
            }
        },
        methods: {
            addFile: function (event, key) {
                let file = event.target.files[0];
                if (file) {
                    file.path = 'javascript:void(0)';
                    this.portfolio[key].certificate = file;
                } else {
                    this.portfolio[key].certificate = null;
                }

            },
            removeFile: function (key) {
                this.portfolio[key].certificate = null;
            },
            changeIndustry: function (val) {
                console.log(val);
            },
            removeWork: function (id, key) {
                this.portfolio.splice(key, 1);
                this.updatePortfolio();
            },
            addWork: function () {
                this.portfolio.push({
                    'description': '',
                    'certificate': null,
                    'customer': '',
                    'industry': {
                        'id': null,
                        'label': 'Отрасль'
                    },
                    'sub_industry': {
                        'id': null,
                        'label': 'подотрасль'
                    },
                    'price': '',
                    'specializationType': {
                        'id': null,
                        'label': 'Специализация'
                    },
                    'status': {
                        'id': null,
                        'label': 'Статус тендера'
                    },
                    'currency': {
                        'id': 1,
                        'label': 'руб'
                    },
                    'id': '',
                    'error': {
                        'name': '',
                        'description': '',
                        'customer': '',
                        'price': ''
                    }
                });
                this.editKey = this.portfolio.length - 1;
            },
            editBlock: function (block, status) {
                this.edit[block] = status ? status : false;
            },
            validate: function () {
                let error = false;

                if (this.portfolio.length) {
                    this.portfolio.forEach(function (e) {
                        if (!e.customer) {
                            e.error.customer = errors.require;
                            error = true;
                        }

                        if (!e.description) {
                            e.error.description = errors.require;
                            error = true;
                        }

                        // if (!e.price) {
                        //     e.error.price = errors.require;
                        //     error = true;
                        // }
                    });
                }

                return !error;
            },
            updatePortfolio: function () {
                let _this = this,
                    fd = new FormData();

                if (!this.ajax.portfolio && this.validate()) {
                    this.ajax.portfolio = true;

                    this.portfolio.forEach(function (e, i) {
                        fd.append('work[' + i + '][description]', e.description);
                        fd.append('work[' + i + '][customer]', e.customer);
                        fd.append('work[' + i + '][industry]', e.industry.id);
                        fd.append('work[' + i + '][sub_industry]', e.sub_industry.id);
                        fd.append('work[' + i + '][price]', e.price);
                        fd.append('work[' + i + '][specializationType]', e.specializationType.id);
                        fd.append('work[' + i + '][status]', e.status.id);
                        fd.append('work[' + i + '][currency]', e.currency.id);
                        fd.append('work[' + i + '][id]', e.id);

                        if (e.certificate) fd.append('certificate' + i, e.certificate);
                    });

                    apiService.send('update-portfolio', fd).then(function (data) {
                        _this.editBlock('portfolio');
                        _this.editKey = null;
                        _this.ajax.portfolio = false;

                        let keys = Object.keys(data['files']);

                        if (keys.length) {
                            keys.forEach(function (i) {
                                _this.portfolio.certificate.path = data['files'][i];
                            });
                        }

                        keys = Object.keys(data['newWorks']);

                        if (keys.length) {
                            keys.forEach(function (i) {
                                _this.portfolio.id = data['newWorks'][i];
                            });
                        }

                    }).catch(function (err) {
                        _this.ajax.portfolio = false;
                    });
                }
            }
        }
    };
</script>

<style scoped>

</style>
