import errors from '../../helpers/errors';
import Vue    from "vue";

export default function () {

    return new Vue({
        el: '#app-menu',
        data:menuData
    });
}
