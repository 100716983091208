<template>
    <div>
        <div class="board myCompany">
            <div class="board-title">
                <p class="rm">{{ userData.legal ? 'О компании' : 'О себе' }}</p>
            </div>
            <div class="board-inf inpName-vis">
                <div v-if="userData.legal"
                     class="inp disabled"
                     :class="{error:error.userData.companyName}">
                    <p class="inpName">Название компании </p>
                    <input type="text"
                           placeholder="Название компании"
                           v-model="companyName">
                    <span class="err ion-a-close"></span>
                    <p class="inpErr">{{error.userData.companyName}}</p>
                </div>
                <div v-if="!userData.legal"
                     class="col-v10 row-wrap">
                    <div class="col-v4 col-p10 mrb24">
                        <div
                            class="inp disabled"
                            :class="{error:error.userData.firstname}">
                            <p class="inpName">Имя</p>
                            <input type="text"
                                   placeholder="Имя"
                                   v-model="firstname">
                            <span class="err ion-a-close"></span>
                            <p class="inpErr">{{error.userData.firstname}}</p>
                        </div>
                    </div>
                    <div class="col-v4 col-p10 mrb24">
                        <div
                            class="inp disabled"
                            :class="{error:error.userData.surname}">
                            <p class="inpName">Фамилия</p>
                            <input type="text"
                                   placeholder="Фамилия"
                                   v-model="surname">
                            <span class="err ion-a-close"></span>
                            <p class="inpErr">{{error.userData.surname}}</p>
                        </div>
                    </div>
                </div>

                <div class="sel country mrb24">
                    <v-select v-model="legal"
                              :options="legalOption">
                        <div slot="no-options">Поиск не дал результатов</div>
                    </v-select>
                </div>
                <div class="inp disabled"
                     :class="{error:error.userData.site}">
                    <p class="inpName">Сайт</p>
                    <input type="text"
                           :value="site"
                           placeholder="Сайт"
                           @change="site = $event.target.value">
                    <span class="err ion-a-close"></span>
                    <p class="inpErr">{{error.userData.site}}</p>
                </div>
                <div class="col-v10 row-wrap">
                    <div class="col-v5 col-p10 mrb24">
                        <div class="sel">
                            <v-select v-model="country"
                                      @input="getCities($event.id)"
                                      :options="$store.state.geo.countries">
                                <div slot="no-options">Поиск не дал результатов</div>
                            </v-select>
                        </div>
                    </div>
                    <div class="col-v5 col-p10 mrb24">
                        <div class="sel ">
                            <v-select
                                v-model="city"
                                @input="getTimeZone"
                                :disabled="country.id === null"
                                :options="$store.state.geo.cities">
                                <div slot="no-options">Поиск не дал результатов</div>
                            </v-select>
                        </div>
                    </div>
                </div>
                <div class="col-v10 sp-btw"
                     v-if="timeRegion">
                    <div class="col-v6 pr0 col-t10">
                        <div class="inp disabled">
                            <p class="inpName">Регион</p>
                            <input type="text"
                                   disabled
                                   placeholder="Регион"
                                   v-model="timeRegion"
                                   value="Московская обл., Местное время 16:30">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="board myCompany">
            <div class="board-title">
                <p class="rm">Реквизиты</p>
            </div>
            <div class="board-inf inpName-vis">
                <div class="col-v10 mrb24">
                    <p>Данные будут видны победителю в тендере для выставления счета</p>
                </div>
                <div class="sp-btw col-v10">
                    <div class="col-v3 col-p10 pr0 mrb24">
                        <div class="inp disabled"
                             :class="{error:error.userData.inn}">
                            <p class="inpName">ИНН</p>
                            <input type="text"
                                   placeholder="ИНН"
                                   v-mask="'############'"
                                   v-model="inn">
                            <span class="err ion-a-close"></span>
                            <p class="inpErr">{{ error.userData.inn }}</p>
                        </div>
                    </div>
                    <div class="col-v3 col-p10 pr0 mrb24">
                        <div class="inp disabled"
                             :class="{error:error.userData.ogrn}">
                            <p class="inpName">ОГРН </p>
                            <input type="text"
                                   placeholder="ОГРН"
                                   v-mask="'###############'"
                                   v-model="ogrn">
                            <span class="err ion-a-close"></span>
                            <p class="inpErr">{{error.userData.ogrn}}</p>
                        </div>
                    </div>
                    <div class="col-v3 col-p10 pr0 mrb24">
                        <div class="inp disabled "
                             :class="{error:error.userData.settlement_account}">
                            <p class="inpName">Расчетный счет </p>
                            <input type="text"
                                   placeholder="Расчетный счет"
                                   v-mask="'#########################'"
                                   v-model="settlement_account">
                            <span class="err ion-a-close"></span>
                            <p class="inpErr">{{error.userData.settlement_account}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="board myCompany">
            <div class="board-title">
                <p class="rm">Контактные данные</p>
            </div>
            <div class="board-inf inpName-vis">
                <div class="col-v10 mrb24">
                    <p>Данные будут видны победителю в тендере для выставления счета</p>
                </div>
                <div class="col-v10 mrb24">
                    <div class="radio">
                        <input type="radio"
                               id="contactMain"
                               @change="contactMain=true"
                               :checked="contactMain">
                        <label for="contactMain" class="radio-lab">
                            Опубликовать тендер с общими контактами компании
                        </label>
                    </div>
                </div>
                <!--<div class="col-v10 mrb45"-->
                     <!--v-if="personal.contacts.length">-->
                    <!--<div class="radio">-->
                        <!--<input type="radio"-->
                               <!--id="contactMain1"-->
                               <!--@change="contactMain=false"-->
                               <!--:checked="!contactMain">-->
                        <!--<label for="contactMain1"-->
                               <!--class="radio-lab">Опубликовать тендер с данными-->
                            <!--сотрудника</label>-->
                    <!--</div>-->
                <!--</div>-->
                <div class="col-v10 row-wrap mrb45"
                     v-if="!contactMain">
                    <div class="sel">
                        <v-select v-model="contact"
                                  :options="contacts">
                            <div slot="no-options">Поиск не дал результатов</div>
                        </v-select>
                    </div>
                </div>

                <div v-if="!contactMain"
                     class="inp disabled"
                     :class="{error:error.userData.contact.email}">
                    <p class="inpName">Основной e-mail </p>
                    <input type="email"
                           placeholder="Основной e-mail"
                           :value="userData.contact.email"
                           @input="setUserDataContact({key:'email',value:$event.target.value})">
                    <span class="err ion-a-close"></span>
                    <p class="inpErr">{{error.userData.contact.email}}</p>
                </div>
                <div v-if="!contactMain"
                     class="inp disabled mrb45"
                     :class="{error:error.userData.contact.phone}">
                    <p class="inpName">Основной телефон </p>
                    <input type="text"
                           placeholder="Основной телефон"
                           v-mask="'+7(###) ###-####'"
                           @input="setUserDataContact({key:'phone',value:$event.target.value})"
                           :value="userData.contact.phone">
                    <span class="err ion-a-close"></span>
                    <p class="inpErr">{{error.userData.contact.phone}}</p>
                </div>

                <div v-if="contactMain"
                     class="inp disabled"
                     :class="{error:error.userData.email}">
                    <p class="inpName">Основной e-mail </p>
                    <input type="email"
                           placeholder="Основной e-mail"
                           v-model="email">
                    <span class="err ion-a-close"></span>
                    <p class="inpErr">{{error.userData.email}}</p>
                </div>
                <div v-if="contactMain"
                     class="inp disabled mrb45"
                     :class="{error:error.userData.phone}">
                    <p class="inpName">Основной телефон </p>
                    <input type="text"
                           placeholder="Основной телефон"
                           v-mask="'+7(###) ###-####'"
                           v-model="phone">
                    <span class="err ion-a-close"></span>
                    <p class="inpErr">{{error.userData.phone}}</p>
                </div>

                <div class="hr width"></div>

                <div class="col-v10">
                    <div class="radio">
                        <input type="radio"
                               id="showContacts"
                               @change="userData.showContactsWinner=0"
                               :checked="!userData.showContactsWinner">
                        <label for="showContacts"
                               class="radio-lab">Отображать основные контактные данные тем
                            исполнителям, которые отправили коммерческое предложение</label>
                    </div>
                </div>
                <div class="col-v10">
                    <div class="radio">
                        <input type="radio"
                               id="showContactsWinner"
                               @change="userData.showContactsWinner=1"
                               :checked="userData.showContactsWinner">
                        <label for="showContactsWinner"
                               class="radio-lab">Отображать основные контактные данные, только
                            победителю</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapMutations,
        mapGetters,
        mapActions,
        mapState
    }                from 'vuex';
    import store     from '../../../../store';
    import VueSelect from 'vue-select';

    export default {
        name:       "step3",
        data() {
            return {
                legalOption: [
                    {
                        id:    0,
                        label: 'Физическое лицо'
                    },
                    {
                        id:    1,
                        label: 'Юридическое лицо'
                    }
                ],
                personal:    personData,
                requsites:   userRequsites,
                timeRegion:  '',
                timer:       null,
            };
        },
        watch:      {
            contact(value) {
                this.$log.debug(value);
            }
        },
        computed:   {
            contacts() {
                if (this.personal.contacts) {
                    return this.personal.contacts.map(e => {
                        e.label = e.email + ' ' + e.phone + ' ' + e.firstname + ' ' + e.patronymic;
                        return e;
                    });
                }
                else {
                    return [];
                }
            }, ...mapState('tender', {
                userData: 'userData',
                error:    'errors'
            }),
            legal:              {
                get() {
                    return this.legalOption.find(e => e.id === this.$store.state.tender.userData.legal);
                },
                set(value) {
                    this.setUserDataByKey({
                        key:   'legal',
                        value: value.id
                    });
                }
            },
            country:            {
                get() {
                    let country = this.$store.state.geo.countries.find(e => e.id === this.$store.state.tender.userData.country_id);
                    return country ? country : {
                        id:    null,
                        label: 'Cтрана'
                    };
                },
                set(value) {
                    this.setUserDataByKey({
                        key:   'country_id',
                        value: value.id
                    });
                }
            },
            city:               {
                get() {
                    let city = this.$store.state.geo.cities.find(e => e.id === this.$store.state.tender.userData.city_id);
                    return city ? city : {
                        id:    null,
                        label: 'Город'
                    };
                },
                set(value) {
                    this.setUserDataByKey({
                        key:   'city_id',
                        value: value.id
                    });
                }
            },
            companyName:        {
                get() {
                    return this.$store.state.tender.userData.companyName;
                },
                set(value) {
                    this.setUserDataByKey({
                        key:   'companyName',
                        value: value
                    });
                }
            },
            contact:            {
                get() {
                    return this.$store.state.tender.userData.contact;
                },
                set(value) {
                    this.setUserDataByKey({
                        key:   'contact',
                        value: value
                    });
                }
            },
            firstname:          {
                get() {
                    return this.$store.state.tender.userData.firstname;
                },
                set(value) {
                    this.setUserDataByKey({
                        key:   'firstname',
                        value: value
                    });
                }
            },
            surname:            {
                get() {
                    return this.$store.state.tender.userData.surname;
                },
                set(value) {
                    this.setUserDataByKey({
                        key:   'surname',
                        value: value
                    });
                }
            },
            site:               {
                get() {
                    return this.$store.state.tender.userData.site;
                },
                set(value) {
                    this.setUserDataByKey({
                        key:   'site',
                        value: value
                    });
                }
            },
            country_id:         {
                get() {
                    return this.$store.state.tender.userData.country_id;
                },
                set(value) {
                    this.setUserDataByKey({
                        key:   'country_id',
                        value: value
                    });
                }
            },
            city_id:            {
                get() {
                    return this.$store.state.tender.userData.city_id;
                },
                set(value) {
                    this.setUserDataByKey({
                        key:   'city_id',
                        value: value
                    });
                }
            },
            inn:                {
                get() {
                    return this.$store.state.tender.userData.inn;
                },
                set(value) {
                    this.setUserDataByKey({
                        key:   'inn',
                        value: value
                    });
                }
            },
            ogrn:               {
                get() {
                    return this.$store.state.tender.userData.ogrn;
                },
                set(value) {
                    this.setUserDataByKey({
                        key:   'ogrn',
                        value: value
                    });
                }
            },
            settlement_account: {
                get() {
                    return this.$store.state.tender.userData.settlement_account;
                },
                set(value) {
                    this.setUserDataByKey({
                        key:   'settlement_account',
                        value: value
                    });
                }
            },
            contactMain:        {
                get() {
                    return this.$store.state.tender.userData.contactMain;
                },
                set(value) {
                    this.setUserDataByKey({
                        key:   'contactMain',
                        value: value
                    });
                }
            },
            showContactsWinner: {
                get() {
                    return this.$store.state.tender.userData.showContactsWinner;
                },
                set(value) {
                    this.setUserDataByKey({
                        key:   'showContactsWinner',
                        value: value
                    });
                }
            },
            email:              {
                get() {
                    return this.$store.state.tender.userData.email;
                },
                set(value) {
                    this.setUserDataByKey({
                        key:   'email',
                        value: value
                    });
                }
            },
            phone:              {
                get() {
                    return this.$store.state.tender.userData.phone;
                },
                set(value) {
                    this.setUserDataByKey({
                        key:   'phone',
                        value: value
                    });
                }
            },
        },
        methods:    {
            getCities(id) {
                window.geoService.getCities(id);
            },
            getTimeZone(event) {
                if (!event.id) return;
                window.geoService.getTimeZone(event.id, e => {
                    clearInterval(this.timer);
                    this.setRegionTime(e);
                    this.timer = setInterval(() => {
                        this.setRegionTime(e);
                    }, 10000);
                });
            },
            setRegionTime(e) {
                let time        = this.$moment().utcOffset(e.timeZone.utc).format('H:mm');
                this.timeRegion = e.region.name + ', Местное время ' + time;
            }, ...mapMutations('tender', [
                'setUserDataByKey',
                'setUserDataContact'
            ])
        },
        store,
        components: {
            'v-select': VueSelect
        },
        mounted() {
            this.$store.dispatch('tender/setUserDataDefaults', {
                personal:  personData,
                requsites: userRequsites,
            });
            this.$store.dispatch('geo/getCountries');
            this.$store.dispatch('geo/getCities');
        }
    };
</script>

<style scoped>

</style>
