import VueMyTenders from './index.vue';
import Vue          from "vue";

export default function () {
    return new Vue({
        el:         '#app-my-tenders',
        template:
`<div id="app-my-tenders" class="app-element">
    <VueMyTenders></VueMyTenders>
</div>`,
        components: {
            VueMyTenders: VueMyTenders
        },
    });
}
