import apiService from './api';

class Geo {

    constructor() {
        this.countries = [];
        this.cities = [];
        this.curCountry = 165;
        this.getCountries();
        this.getCities(165);
    }

    getTimeZone(city_id,next){
        return apiService.send('timeZone',{id:city_id}).then(data=>next(data.data)).catch(error => console.error(error));
    }

    getCountries() {
        apiService.send('countries').then(data => {
            this.countries = data.data;
            let callback   = new Event('countries_load');
            window.dispatchEvent(callback);
        }).catch(error => console.error(error));
    }


    getCities(id) {
        if (this.cities.length && id == this.curCountry) return;
        apiService.send('city', {country_id: id}).then(data => {
            this.cities  = data.data;
            let callback = new Event('cities_load');
            window.dispatchEvent(callback);
        }).catch(error => console.error(error));
    }
}

export default new Geo();
