<template>
    <div class="createTend-tabsContent">
        <div class="board myCompany ">
            <slot></slot>
        </div>
        <div class="jc-end col-v10 creation-tendBtn" ref="stepBtn">
            <a href="/tender-open" v-if="step === 0"
               class="grey rm cancel mrr48"
            >Вернуться к списку создания тендеров</a>
            <a v-if="step > 0"
               class="grey rm cancel mrr48"
               href="javascript:void(0)"
               v-scroll-to="'#layout-content'"
               @click="setActive(step-1)">Назад</a>
            <div
                @click="setActive(step+1)"
                v-scroll-to="'#layout-content'"
                class="create btn colorGreen btn180">
                <span class="colorGreen-hover"></span>
                <span class="zi">{{ list[step].nextTitle }}</span>
            </div>
        </div>
        <div class="jc-end col-v10" v-if="stepError">
            <span style="color:red">Проверьте правильность заполненых полей</span>
        </div>
    </div>
</template>

<script>
    export default {
        name:     "tabContent",
        data:     function () {
            return {};
        },
        computed: {
            step() {
                return this.list.findIndex(e => {
                    return e.active;
                });
            },
            stepError(){
                return this.$store.getters['tender/errorInStep'](this.step+1)
            }
        },
        props:    {
            list: Array,
        },
        methods:  {
            setActive(key) {
                this.$emit('changeStep', key);
            }
        }
    };
</script>

<style scoped>

</style>
