import VueCreateTender from './index.vue';
import Vue          from "vue";

export default function () {
    return new Vue({
        el:         '#app-create-tender',
        template:   `
<section class="container app-element" id="app-create-tender">
    <VueCreateTender></VueCreateTender>
</section>
        `,
        components: {
            VueCreateTender: VueCreateTender
        },
    });
}
