import Vue        from "vue";
import apiService from '../../services/api';
import flashMsg   from "../../services/flashMsg";

export default function () {

    return new Vue({
        el:      '#app-avatar',
        data:    avatarData,
        methods: {
            addAvatar:   function () {
                this.$refs['file'].click();
            },
            attachPhoto: function (event) {
                if (event.target.files) {
                    this.file = event.target.files[0];
                    this.uploadPhoto();
                }
                else {
                    this.file = null;
                }
            },
            uploadPhoto: function () {
                var fd    = new FormData(),
                    _this = this;

                fd.append('avatar', this.file);

                var ValidImageTypes = ["image/jpeg", "image/png"];
                if ($.inArray(this.file.type, ValidImageTypes) < 0) {
                    flashMsg.add("Аватар должен иметь формат jpeg/png", 'error');
                    return false;
                } else {
                    apiService.send('avatar', fd).then(function (data) {
                        _this.img = data.files['avatar'];
                        _this.$refs['img'].src = _this.img;
                        flashMsg.add("Вы успешно обновили аватар");
                    }).catch(function (data) {
                        if (data.hasOwnProperty('responseJSON')) {
                            flashMsg.add(data.responseJSON.error, 'error');
                        }
                    });
                }
            }
        }

    });
}
