export default {
    'refresh':               {
        type: 'POST',
        url:  '/api/refresh'
    },
    'api-token':             {
        type: 'GET',
        url:  '/api/token'
    },
    'invalidate':            {
        type: 'POST',
        url:  '/api/invalidate'
    },
    'login':                 {
        type: 'POST',
        url:  '/api/login'
    },
    'auth':                  {
        type: 'POST',
        url:  '/api/auth'
    },
    'forgot-password':       {
        type: 'POST',
        url:  '/api/forgot-password'
    },
    'reset-password':        {
        type: 'POST',
        url:  '/api/reset-password'
    },
    'signup':                {
        type: 'POST',
        url:  '/api/register'
    },
    'activate':              {
        type: 'POST',
        url:  '/api/account-activation'
    },
    'user-update':           {
        type: 'POST',
        url:  '/api/user/update'
    },
    'get-restore-code-phone':       {
        type: 'POST',
        url:  '/api/user/get-restore-code-phone'
    },
    'check-restore-code-phone':       {
        type: 'POST',
        url:  '/api/user/check-restore-code-phone'
    },
    'get-activation-code':       {
        type: 'POST',
        url:  '/api/user/get-activation-code'
    },
    'confirm-activation-code':       {
        type: 'POST',
        url:  '/api/user/confirm-activation-code'
    },
    'company-update':        {
        type: 'POST',
        url:  '/api/company/update'
    },
    'avatar':                {
        type: 'POST',
        url:  '/api/avatar'
    },
    'password-reset':        {
        type: 'POST',
        url:  '/api/password/reset'
    },
    'update-requisites':     {
        type: 'POST',
        url:  '/api/requisites/update'
    },
    'update-portfolio':      {
        type: 'POST',
        url:  '/api/portfolio/update'
    },
    'specialization-update': {
        type: 'POST',
        url:  '/api/specialization/update'
    },
    'contacts-update':       {
        type: 'POST',
        url:  '/api/contacts/update'
    }
};
