import login            from './components/profile/login';
import register         from './components/profile/register';
import person           from './components/profile/person';
import options          from './components/profile/options';
import paymentInf       from './components/profile/paymentInf';
import portfolio        from './components/profile/portfolio';
import resetPassByEmail from './components/profile/resetPassByEmail';
import avatar           from './components/profile/avatar';
import menu             from './components/profile/menu';
import calls            from './components/calls';
import createTender     from './components/tender/create';
import myTenders        from './components/tender/my-tenders';
import tenders          from './components/tender/tenders';
// import notification     from './components/notification';

export default {
    'login':            login,
    'register':         register,
    'person':           person,
    'options':          options,
    'paymentInf':       paymentInf,
    'portfolio':        portfolio,
    'resetPassByEmail': resetPassByEmail,
    'avatar':           avatar,
    'menu':             menu,
    'calls':            calls,
    'createTender':     createTender,
    'myTenders':        myTenders,
    'tenders':          tenders,
};
