import Vue from 'vue';
import api from '../../services/api';

// initial state
const state = {
    currencies: window.currencies,
    services:   [],
    timezones:  [],
};

let getOptions = (data, all, key) => {
    let items = data.map((e) => {
        return {
            id:    e.id,
            label: key ? e[key] : e.name
        };
    });
    if (all) {
        items.unshift({
            id:    null,
            label: all
        });
    }

    return items;
};

// getters
const getters = {
    getShortTimeZones(state) {
        let items = [];
        state.timezones.forEach(e => {
            if (items.indexOf(e.label) === -1) items.push(e.label);
        });
        return items.sort();
    },
    getService(serviceId) {
        let item = null;
        if (state.services.hasOwnProperty(serviceId)) {
            item = state.services[serviceId];
        }

        return item;
    }
};

// actions
const actions = {
    getOptions({state, commit}) {
        if (window.hasOwnProperty('tenderServices') && window.tenderServices) {
            commit('setServices', window.tenderServices);
        }
    },
    getTimeZones({state, commit}) {
        if (!state.timezones.length) {
            api.send('timezones').then(data => commit('setTimeZones', getOptions(data.data, null, 'utc'))).catch(err => Vue.$log.error(err));
        }
    }
};

// mutations
const mutations = {
    setServices(state, value) {
        state.services = value;
    },
    setTimeZones(state, value) {
        state.timezones = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
