import Vue               from "vue";
import RegisterComponent from "./register.vue";

export default function () {
    return new Vue({
        el: '#vue-register-app',
        template: `
        <section id="vue-register-app" class="app-element row">
            <register></register>
        </section>
        `,
        components: {
            'register': RegisterComponent
        }
    });
}
