import errorMessages from './helpers/errors';
import Vue           from 'vue';
import VueTheMask    from 'vue-the-mask';
import Vuex          from 'vuex';
import VueRouter     from 'vue-router';
import {ru}          from 'vuejs-datepicker/dist/locale';
import Geo           from './services/geo';
import flash         from './services/flashMsg';
import VueScrollTo   from 'vue-scrollto';
import VueLogger     from 'vuejs-logger';
import VueMoment     from 'vue-moment';
import moment        from 'moment-timezone';
import Echo          from "laravel-echo";
import prupal        from './helpers/prupal'

window.io = require('socket.io-client');
require('../../../../modules/system/assets/ui/vendor/bootstrap/js/transition');

const isProduction = process.env.NODE_ENV === 'production';

const options = {
    isEnabled:          true,
    logLevel:           isProduction ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel:       true,
    showMethodName:     true,
    separator:          '|',
    showConsoleColors:  true
};

Vue.use(VueMoment, {
    moment,
});
Vue.use(prupal, prupal);
Vue.use(VueLogger, options);
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueTheMask);
Vue.use(VueScrollTo, {
    container:  "#layout-content",
    duration:   500,
    easing:     "ease",
    offset:     0,
    force:      true,
    cancelable: true,
    onStart:    false,
    onDone:     false,
    onCancel:   false,
    x:          false,
    y:          true
});

import components from './components.js';

window.ru            = ru;
window.flashMsg      = flash.add;
window.geoService    = Geo;
window.vueComponents = components;
window.errors        = errorMessages;
window.echo          = Echo;
