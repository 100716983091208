<template>
    <div>
        <div class="board myCompany ">
            <div class="board-title">
                <p class="rm">С кем Вы хотите работать?</p>
            </div>
            <div class="board-inf radioBox ">
                <div class="col-v10 mrb24">
                    <p>Компании дороже, но надежнее: договор, репутация, опыт, безналичная оплата. <br>
                        Фрилансеры дешевле, но с ними надо уметь работать. Вам придется самим ставить
                        задачу, все контролировать, проверять.</p>
                </div>
                <div class="col-v10 mrb24">
                    <div class="radio">
                        <input type="radio"
                               id="performer_type0"
                               @change="setPerformer_type(0)"
                               :checked="performer_type===0">
                        <label for="performer_type0"
                               class="radio-lab">Любой участник сервиса</label>
                    </div>
                </div>
                <div class="col-v10 mrb24">
                    <div class="radio">
                        <input type="radio"
                               id="performer_type1"
                               :checked="performer_type===1"
                               @change="setPerformer_type(1)"
                        >
                        <label for="performer_type1"
                               class="radio-lab">Физическое лицо</label>
                    </div>
                </div>
                <div class="col-v10">
                    <div class="radio">
                        <input type="radio"
                               id="performer_type2"
                               :checked="performer_type===2"
                               @change="setPerformer_type(2)">
                        <label for="performer_type2"
                               class="radio-lab">Юридическое лицо</label>
                    </div>
                </div>
            </div>

        </div>

        <div class="board myCompany">
            <div class="board-title">
                <p class="rm">Где должен находиться исполнитель</p>
            </div>
            <div class="board-inf">
                <div class="col-v10 mrb24">
                    <p>Рекомендуем выбирать город, только если для Вас очень важно встречаться с
                        исполнителями лично или иметь один часовой пояс. Если такой потребности нет –
                        оставьте «Любой город». Так Вы получите гораздо больше предложений.</p>
                </div>
                <div class="col-v10 row-wrap">
                    <div class="col-v5 col-p10 mrb24">
                        <div class="sel">
                            <v-select v-model="country"
                                      :options="$store.state.geo.countries">
                                <div slot="no-options">Поиск не дал результатов</div>
                            </v-select>
                        </div>
                    </div>
                    <div class="col-v5 col-p10 mrb24">
                        <div class="sel ">
                            <v-select v-model="city"
                                      :disabled="country.id === null"
                                      :options="$store.state.geo.cities">
                                <div slot="no-options">Поиск не дал результатов</div>
                            </v-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="board myCompany">
            <div class="board-title">
                <p class="rm">Навыки исполнителя</p>
            </div>
            <div class="board-inf">
                <div class="col-v10 mrb24">
                    <p>Здесь Вы можете написать в свободной форме  какими навыками должен обладать исполнитель.</p>
                </div>
                <div class="col-v10">
                    <label class="inp disabled">
                        <p class="inpName">Какими специальными навыками должен обладать исполнитель?</p>
                        <input type="text"
                               @input="setSkills($event.target.value)"
                               :value="skills"
                               placeholder="Какими специальными навыками должен обладать исполнитель?">
                        <span class="err ion-a-close"></span>
                        <p class="inpErr">что то или ничего</p>
                    </label>
                </div>
            </div>
        </div>


        <div class="board myCompany ">
            <div class="board-title">
                <p class="rm">Кто увидит тендер и сможет сделать предложение?</p>
            </div>
            <div class="board-inf">
                <div class="col-v10 mrb24">
                    <p>Компании дороже, но надежнее: договор, репутация, опыт, безналичная оплата. <br>
                        Фрилансеры дешевле, но с ними надо уметь работать. Вам придется самим ставить
                        задачу, все контролировать, проверять.</p>
                </div>
                <div class="col-v10 mrb24">
                    <div class="radio">
                        <input type="radio"
                               id="allow_view1"
                               @change="setAllow_view(1)"
                               :checked="allow_view===1">
                        <label for="allow_view1"
                               class="radio-lab">Только участники со статусом</label>
                        <div class="tag">ATS</div>
                        <p class="grey pl40">Исполнители с ATS-аккаунтами прошли проф. тестирование и
                            обладают достаточным опытом работы</p>
                    </div>

                </div>
                <div class="col-v10 mrb45">
                    <div class="radio">
                        <input type="radio"
                               id="allow_view0"
                               @change="setAllow_view(0)"
                               :checked="allow_view===0">
                        <label for="allow_view0"
                               class="radio-lab">Любой участник</label>
                        <p class="grey pl40">Тендер будет публичным и его увидят все участники сервиса,
                            любой может принять участие</p>
                    </div>
                </div>
                <div class="hr width"></div>
                <div class="col-v10 mrb45">
                    <p class="grey">Назначьте вознаграждение специалисту</p>
                </div>
                <div class="col-v10">
                    <div
                        class="wrap-forkCost col-v10 ">
                        <div class="forkCost">
                            <v-range-slider :min="service.min_price"
                                            :max="service.max_price"
                                            :value="min_price"
                                            :interval="500"
                                            @input="min_price = $event"
                            ></v-range-slider>
                            <div class="wrap-forkCost-inp">
                                <div class="sp-btw">
                                    <div class="forkCost-inp">{{ service.min_price }}</div>
                                    <div class="forkCost-inp">{{ service.max_price }}</div>
                                </div>
                                <div class="sel pr0">
                                    <v-select v-model="currency"
                                              :options="$store.state.options.currencies">
                                        <div slot="no-options">Поиск не дал результатов</div>
                                    </v-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-v10 forkCost-prise">
                        <span class="inf-advice-ic">!</span>
                        <p class="grey dib">Как правило, средняя стоимость за похожие проекты составляет</p>
                        <span class="blackLight dib">{{ service.standard_price }} руб</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="board myCompany">
            <div class="board-title">
                <p class="rm">Вопросы к исполнителю</p>
            </div>
            <div class="board-inf">
                <div class="col-v10 mrb24">
                    <p>Здесь Вы можете написать свои вопросы исполнителям. Ответы на них часто говорят
                        больше, чем карточка исполнителя и его отзывы.</p>
                </div>
                <div class="col-v10 mrb24"
                     v-for="(question,key) in questions">
                    <label class="inp disabled">
                        <input type="text"
                               @input="setQuestion({value:$event.target.value,key:key})"
                               :value="question.text"
                               placeholder="Ваш вопрос?">
                        <span class="err ion-a-close"></span>
                        <span @click="removeQuestion(key)"
                              class="del ion-a-close"></span>
                        <p class="inpErr">что то или ничего</p>
                    </label>

                </div>
                <div class="col-v10">
                    <a href="#"
                       @click.stop.prevent="addQuestion"
                       class="add ion-plus grey">Написать вопрос</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapMutations,
        mapGetters,
        mapActions,
        mapState
    }                  from 'vuex';
    import store       from '../../../../store';
    import VueSelect   from 'vue-select';
    import VueSlider   from 'vue-slider-component';

    export default {
        name:       "step2",
        computed:   {
            currency:  {
                get() {
                    if (this.currency_id === null) {
                        this.$store.commit('tender/setCurrency', 1);
                    }
                    return this.$store.state.options.currencies.find(e => e.id === this.currency_id);
                },
                set(value) {
                    this.setCurrency(value.id);
                }
            },
            country:   {
                get() {
                    let country = this.$store.state.geo.countries.find(e => e.id === this.country_id);
                    return country ? country : {
                        id:    null,
                        label: 'Cтрана'
                    };
                },
                set(value) {
                    this.setCountry(value.id);
                    this.getCities(value.id);
                }
            },
            city:      {
                get() {
                    let city = this.$store.state.geo.cities.find(e => e.id === this.city_id);
                    return city ? city : {
                        id:    null,
                        label: 'Город'
                    };
                },
                set(value) {
                    this.setCity(value.id);
                }
            },
            service:    {
                get() {
                    return this.$store.state.options.services.find(e => e.id === parseInt(this.$store.state.tender.service_id))
                },
                set(value) {
                    this.setServices(value);
                }
            },
            min_price: {
                get() {
                    let price = this.$store.state.tender.min_price;
                    return price ? price : this.service.standard_price;
                },
                set(value) {
                    this.setMin_price(value);
                }
            },
            ...mapState('tender', [
                'performer_type',
                'allow_view',
                'city_id',
                'country_id',
                'skills',
                'currency_id',
                'questions',
            ]),
            ...mapState('options', [
                'services'
            ]),
            error() {
                return this.$store.state.tender.errors;
            }
        },
        store,
        methods:    {
            ...mapMutations('tender', [
                'setPerformer_type',
                'setAllow_view',
                'setSkills',
                'setCountry',
                'setCity',
                'setMin_price',
                'setCurrency',
                'setQuestions',
                'setQuestion',
                'addQuestion',
                'removeQuestion',
            ]),
            ...mapActions('options', [
                'getOptions'
            ]),
            getCities(id) {
                window.geoService.getCities(id);
            },
            isNumber: function (evt) {
                evt          = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                }
                else {
                    return true;
                }
            },
        },
        components: {
            'v-select': VueSelect,
            'v-range-slider': VueSlider
        },
        mounted() {
            this.$store.dispatch('geo/getCountries');
            this.$store.dispatch('geo/getCities');
            this.$store.dispatch('options/getOptions');
        }
    };
</script>

<style scoped>

</style>
