<template>
    <aside class="aside">
        <div class="ion-chevron-down" @click="$emit('openFilter', true)"></div>
        <div class="para">
            <div class="asPerformers-title open">
                <div class="toggle-param">
                    <span class="ion-chevron-up "></span>
                    <h3 class="darkBlue">Специализация</h3>
                </div>
                <div class="mrb24"
                     v-for="item in services">
                    <a href="#"
                       @click.stop.prevent="$emit('changeService',item.id)"
                       :class="{grey:service!==item.id}">{{item.name}}</a>
                </div>
            </div>
        </div>
        <div class="para">
            <div class="asPerformers-title open">
                <div class="toggle-param">
                    <span class="ion-chevron-up "></span>
                    <h3 class="darkBlue">Исполнителям</h3>
                </div>
            </div>
            <div class="block-dropdown">
                <div class="radio">
                    <input type="radio"
                           id="prf"
                           name="filter-performer-type"
                           @change="$emit('changePerformerType',0)"
                           :checked="performer_type===0">
                    <label for="prf"
                           class="radio-lab">Все</label>
                </div>
                <div class="radio">
                    <input type="radio"
                           id="prf1"
                           name="filter-performer-type"
                           :checked="performer_type===1"
                           @change="$emit('changePerformerType',1)"
                    >
                    <label for="prf1"
                           class="radio-lab">Физическое лицо</label>
                </div>
                <div class="radio">
                    <input type="radio"
                           id="prf2"
                           name="filter-performer-type"
                           @change="$emit('changePerformerType',2)"
                           :checked="performer_type===2"
                    >
                    <label for="prf2"
                           class="radio-lab">Юридическое лицо</label>
                </div>
                <div class="statAts">
                    <input type="checkbox"
                           id="ats-allow_view"
                           @change="$emit('changeAllowView')"
                           :checked="allow_view===1"
                    >
                    <label for="ats-allow_view"
                           class="statAts-lab">Только со статусом ATS
                        <span v-if="tooltipOnlyStatusAts" class="ion-ios-help-outline">
                             <span class="helpOutline-open">
                                <span class="helpOutline-open-text">
                                    <p>{{ tooltipOnlyStatusAts }}</p>
                                </span>
                            </span>
                        </span>
                    </label>
                </div>
            </div>
        </div>
        <div class="para">
            <div class="asPerformers-title open">
                <div class="toggle-param">
                    <span class="ion-chevron-up "></span>
                    <h3 class="darkBlue rb">Отрасли</h3>
                </div>
            </div>
            <div class="block-dropdown">
                <div class="asPerformers-subtitle sel">
                    <vSelect v-model="selectIndustry"
                             :placeholder="'Выберите из списка'"
                             :name="'filter-industry'"
                             :options="industries">
                        <div slot="no-options">Поиск не дал результатов</div>
                    </vSelect>
                </div>
                <!--<div class="radio"-->
                     <!--v-for="item in industries">-->
                    <!--<input type="radio"-->
                           <!--:id="'otr'+item.id"-->
                           <!--name="filter-industry"-->
                           <!--@change="$emit('changeIndustry',item.id)"-->
                           <!--:checked="industry === item.id || (item.id === 0 && industry === null)"-->
                    <!--&gt;-->
                    <!--<label :for="'otr'+item.id"-->
                           <!--class="radio-lab">{{item.label}}</label>-->
                <!--</div>-->
            </div>
        </div>
        <div class="para">
            <div class="asPerformers-title open">
                <div class="toggle-param">
                    <span class="ion-chevron-up"></span>
                    <h3 class="rb" :class="{darkBlue: sub_industry, grey: !sub_industry}">Подотрасль</h3>
                </div>
            </div>
            <div class="block-dropdown">
                <div class="asPerformers-subtitle sel">
                    <vSelect v-model="selectSubIndustry"
                             :placeholder="'Выберите из списка'"
                             :options="sub_industries">
                        <div slot="no-options">Поиск не дал результатов</div>
                    </vSelect>
                </div>
            </div>
        </div>

        <div class="para">
            <div class="asPerformers-title open">
                <div class="toggle-param">
                    <span class="ion-chevron-up"></span>
                    <h3 class="darkBlue">Часовой пояс</h3>
                </div>
            </div>
            <div class="block-dropdown">
                <div class="asPerformers-subtitle sel">
                    <vSelect v-model="selectTimeZone"
                             :placeholder="'Выберите из списка'"
                             :options="timezones"></vSelect>
                </div>
            </div>
        </div>
        <!--<div class="para">-->
            <!--<div class="asPerformers-title">-->
                <!--<div class="toggle-param">-->
                    <!--<span class="ion-chevron-up"></span>-->
                    <!--<h3 class="darkBlue">Город</h3>-->
                <!--</div>-->
            <!--</div>-->
            <!--<div class="block-dropdown">-->
                <!--<div class="asPerformers-subtitle sel">-->
                    <!--<vSelect v-model="selectTimeZone"-->
                             <!--:placeholder="'Выберите из списка'"-->
                             <!--:options="timezones"></vSelect>-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->

        <div class="para">
            <div class="asPerformers-title open">
                <div class="toggle-param">
                    <span class="ion-chevron-up "></span>
                    <h3 class="darkBlue">Статус</h3>
                </div>
            </div>

            <div class="block-dropdown">
                <div class="radio"
                     v-for="(item,key) in tenderStatuses">
                    <input type="radio"
                           :id="'status'+key"
                           name="filter-status"
                           @change="$emit('changeStatus',key)"
                           :checked="status==key || (!status && key == 2)">
                    <label :for="'status'+key"
                           class="radio-lab">{{item}}</label>
                </div>
            </div>
        </div>
        <div class="para" v-if="is_customer">
            <div class="createTend">
                <p class="grey mrb24">На сайте {{countPerformers}} <br>
                    Создайте тендер за 5 минут и получите реальные заявки исполнителей с бюджетами и сроками.</p>
                <a href="/tender-open"
                   class="create btn colorGreen">
                    <span class="colorGreen-hover"></span>
                    <span class="zi">Создать тендер</span>
                </a>
            </div>
        </div>
    </aside>
</template>

<script>
    import store from '../../../store';
    import prupal from '../../../helpers/prupal'
    import {mapActions} from 'vuex';
    import vSelect from 'vue-select';

    export default {
        name: "vue-aside",
        store,
        props: {
            is_customer: Number,
            timezone: String,
            service: Number,
            industry: Number,
            sub_industry: Number,
            allow_view: Number,
            performer_type: Number,
            status: Number
        },
        data() {
            return {
                tooltipOnlyStatusAts: JSON.parse(tooltips).hasOwnProperty('only_status_ats') ? JSON.parse(tooltips).only_status_ats : '',
                countPerformers: prupal(window.countPerformers, ['исполнитель', 'исполнителя', 'исполнителей']),
                tenderStatuses: {
                    2: 'Прием заявок открыт',
                    3: 'Прием заявок остановлен',
                    4: 'Прием заявок завершен',
                }
            };
        },
        computed: {
            selectIndustry: {
                get() {
                    return this.industry ? this.$store.state.industry.industries.find(e => e.id === this.industry) : {
                        id: 0,
                        label: 'Любая'
                    }
                },
                set(value) {
                    if (typeof value === 'object' && value.hasOwnProperty('id')) {
                        this.$emit('changeIndustry', value.id);
                    }
                }
            },
            selectSubIndustry: {
                get() {
                    return this.sub_industries.length ? this.sub_industries.find(e => e.id === this.sub_industry) : null;
                },
                set(value) {
                    this.$emit('changeSubIndustry', value ? value.id : null);
                }
            },
            industries() {
                return this.$store.state.industry.industries;
            },
            sub_industries() {
                return this.$store.getters['industry/getSubIndustries'](this.industry);
            },
            services() {
                return this.$store.state.options.services;
            },
            timezones() {
                return this.$store.getters['options/getShortTimeZones'].map(e => {
                    return {
                        id: e,
                        label: e
                    };
                });
            },
            selectTimeZone: {
                get() {
                    return this.timezones.find(e => e.id == this.timezone);
                },
                set(value) {
                    this.$emit('changeTimezone', value ? value.id : null);
                }
            }
        },
        methods: {
            ...mapActions('options', ['getOptions']),
        },
        components: {
            vSelect
        },
        mounted() {
            this.$store.dispatch('options/getOptions');
            this.$store.dispatch('industry/getIndustries');
            // this.$store.dispatch('industry/getSubIndustries');
            this.$store.dispatch('options/getTimeZones');
        }

    };
</script>

<style scoped>

</style>
