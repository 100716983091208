// initial state
const state = {
    countries: [],
    cities:    [],
};

// getters
const getters = {};

let getCountries = () => {
    return window.geoService.countries.map(e=>{
        return {
            id:    e.id,
            label: e.name
        };
    })
};

let getCities = () => {
    let cities = window.geoService.cities.map((e) => {
        return {
            id:    e.id,
            label: e.name
        };
    });
    cities.unshift({id:null,label:'Любой город'});

    return cities;
};

// actions
const actions = {
    getCountries({state, commit}) {
        if (window.hasOwnProperty('geoService') && window.geoService.countries.length) {
            commit('setCountries', getCountries());
        }
        else {
            window.addEventListener('countries_load', function () {
                commit('setCountries', getCountries());
            });
        }
    },
    getCities({state, commit}) {
        if (window.hasOwnProperty('geoService') && window.geoService.cities.length) {
            commit('setCities', getCities());
        }

        window.addEventListener('cities_load', function () {
            commit('setCities', getCities());
        });
    }
};

// mutations
const mutations = {
    setCountries(state, items) {
        state.countries = items;
    },
    setCities(state, items) {
        state.cities = items;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
