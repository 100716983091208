// initial state
const state = {
    industries:     [],
    sub_industries: [],
};

// getters
const getters = {
    getIndustries: state => id => {
        if (!id) return [];
        return state.industries[id];
    },
    getSubIndustries: state => id => {
        let items = [];
        if (id && state.sub_industries.hasOwnProperty(id)) {
            state.sub_industries[id].forEach(item => {
                items.push({
                    label: item.label,
                    id: item.id
                });
            })
        }

        return items;
    }
};

// actions
const actions = {
    getIndustries({state, commit}) {
        console.log('actions', commit);
        if (window.hasOwnProperty('industries') && window.industries) {
            commit('setIndustries', window.industries);
        }
        if (window.hasOwnProperty('sub_industries') && window.sub_industries) {
            commit('setSubIndustries', window.sub_industries);
        }
    },
    getSubIndustries({state, commit}) {
    }
};

// mutations
const mutations = {
    setIndustries(state, items) {
        console.log('mutations', items);
        state.industries = items;
    },
    setSubIndustries(state, items) {
        state.sub_industries = items;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
