<template>
    <div>
        <div class="mrb45 sp-btw">
            <h2 class="udr">Проекты</h2>
        </div>
        <div class="row-wrap">
            <vue-aside
                    :is_customer="isCustomer"
                    :service="service"
                    :industry="industry"
                    :sub_industry="sub_industry"
                    :performer_type="performer_type"
                    :timezone="timezone"
                    :status="status"
                    :allow_view="allow_view"
                    @openFilter="openFilter = $event"
                    @changeService="service = $event"
                    @changePerformerType="performer_type = $event"
                    @changeAllowView="allow_view = allow_view===1?null:1"
                    @changeIndustry="industry = $event"
                    @changeSubIndustry="sub_industry = $event"
                    @changeStatus="status = $event"
                    @changeTimezone="timezone = $event"
            ></vue-aside>
            <section class="section verifySp brdL-non">

                <sort :order="order"
                      :order-dir-asc="orderDirAsc"
                      @changeOrder="changeOrder"
                      :list="orderList"></sort>
                <div class="col-v10">
                    <div class="wrap-tender app-element">
                        <div v-if="ajax"
                             class="loading">
                            <div class="spinner"></div>
                        </div>
                        <div v-if="tenders.length">
                            <div v-show="!ajax"
                                 v-for="item in tenders" class="tend-last">
                                <tender
                                        :tender="item"></tender>
                            </div>
                        </div>
                    </div>
                    <div v-if="!tenders.length&&!ajax" class="recover filter mrb45">
                        <div class="col-v10 center mrb24">
                            <div class="load-ic ic"></div>
                        </div>
                        <div class="col-v10 center">
                            <p class="grey">К сожалению, по Вашему запросу тендеров не найдено.</p>
                            <p class="grey">Попробуйте изменить фильтры.</p>
                        </div>

                    </div>
                </div>

                <div class="col-v10"
                     v-if="page<maxPage">
                    <a href="#"
                       @click.stop.prevent="moreTenders"
                       class="brGrey btn w100">Показать больше</a>
                </div>
                <div v-if="!tenders.length&&!ajax" class="col-v10 center">
                    <a href="#" @click.stop.prevent="clearFilter" class="btn brGreen btn217">Сбросить фильтры</a>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import aside  from './aside';
    import sort   from './sort';
    import tender from '../tender';
    import store  from '../../../store';
    import _      from 'lodash';
    import api    from '../../../services/api';

    export default {
        name: "index",
        data() {
            return {
                ...personData,
                tenders: [],
                page: 1,
                maxPage: 1,
                openFilter: false,
                default: {
                    order: 'created_at',
                    orderDirAsc: false,
                    service: null,
                    status: null,
                    industry: null,
                    sub_industry: null,
                    allow_view: null,
                    timezone: null,
                    performer_type: 0
                },
                orderList: {
                    'created_at': 'Дата создания',
                    'start_date': 'Крайняя дата принятия заявки',
                    'min_price': 'По стоимости'
                },
                timer: null,
                ajax: false
            };
        },
        store,
        components: {
            tender,
            'vue-aside': aside,
            sort
        },
        methods: {
            clearFilter() {
                this.$router.push({query: {}});
                this.filter();
            },
            moreTenders() {
                this.page++;
                this.getTenders();
            },
            getDataFiler() {
                let filter = {
                    page: this.page,
                    order: this.order,
                    orderDir: this.orderDirAsc ? 'asc' : 'desc'
                };

                if (this.service) filter.service = this.service;
                if (this.status) filter.status = this.status;
                if (this.industry) filter.industry = this.industry;
                if (this.sub_industry) filter.sub_industry = this.sub_industry;
                if (this.allow_view) filter.allow_view = this.allow_view;
                if (this.timezone) filter.timezone = this.timezone;
                if (this.performer_type) filter.performer_type = this.performer_type;

                return filter;
            },
            getTenders(clear) {
                this.timer = setTimeout(() => {
                    clearTimeout(this.timer);
                    this.ajax = true;
                    api.send('tenders', this.getDataFiler()).then(data => {
                        this.ajax = false;
                        this.tenders = clear ? data.items : this.tenders.concat(data.items);
                        this.maxPage = data.maxPage;
                    }).catch(err => {
                        this.$log.error(err);
                        this.ajax = false;
                    });
                }, 200);
            },
            changeOrder({order, orderDirAsc}) {
                this.$log.debug(order, orderDirAsc);
                this.order = order;
                this.orderDirAsc = orderDirAsc;
            },
            filter() {
                this.page = 1;
                this.getTenders(true);
            },
            setQuery(key, value) {
                let q = {
                    query: {
                        ...this.$route.query
                    }
                };
                if (!_.isNull(value) && !_.isNaN(parseInt(value))) {
                    q.query[key] = parseInt(value);
                } else {
                    delete q.query[key];
                }
                this.$router.push(q);
                if (this.openFilter && window.innerWidth < 960) {
                    $('html, body').animate({
                        scrollTop: $('.wrap-tender.app-element').offset().top - $('header').outerHeight()
                    }, 1000);
                }
                this.filter();
            }
        },
        computed: {
            query() {
                return this.$route.query;
            },
            service: {
                get() {
                    return _.isNaN(parseInt(this.$route.query.service)) ? this.default.service : parseInt(this.$route.query.service);
                },
                set(value) {
                    value = this.service === value ? null : value;
                    this.setQuery('service', value);
                }
            },
            status: {
                get() {
                    return _.isNaN(parseInt(this.$route.query.status)) ? this.default.status : parseInt(this.$route.query.status);
                },
                set(value) {
                    this.setQuery('status', value);
                }
            },
            industry: {
                get() {
                    return _.isNaN(parseInt(this.$route.query.industry)) ? this.default.industry : parseInt(this.$route.query.industry);
                },
                set(value) {
                    this.setQuery('industry', value);
                    this.setQuery('sub_industry', null);
                }
            },
            sub_industry: {
                get() {
                    return _.isNaN(parseInt(this.$route.query.sub_industry)) ? null : parseInt(this.$route.query.sub_industry);
                },
                set(value) {
                    if (!_.isNaN(parseInt(value))) {
                        this.setQuery('sub_industry', value);
                    }
                }
            },
            performer_type: {
                get() {
                    return _.isNaN(parseInt(this.$route.query.performer_type)) ? this.default.performer_type : parseInt(this.$route.query.performer_type);
                },
                set(value) {
                    this.setQuery('performer_type', value);
                }
            },
            allow_view: {
                get() {
                    return _.isNaN(parseInt(this.$route.query.allow_view)) ? this.default.allow_view : parseInt(this.$route.query.allow_view);
                },
                set(value) {
                    this.setQuery('allow_view', value);
                }
            },
            timezone: {
                get() {
                    return !this.$route.query.timezone ? this.default.timezone : this.$route.query.timezone;
                },
                set(value) {
                    let q = {
                        query: {
                            ...this.$route.query
                        }
                    };
                    if (!_.isNull(value) && value) {
                        q.query.timezone = value;
                    } else {
                        delete q.query.timezone;
                    }
                    this.$router.push(q);
                    this.filter();
                }
            },
            order: {
                get() {
                    return this.$route.query.order ? this.$route.query.order : this.default.order;
                },
                set(value) {
                    this.$router.push({
                        query: {
                            ...this.$route.query,
                            order: value
                        }
                    });
                    this.filter();
                }
            },
            orderDirAsc: {
                get() {
                    return this.$route.query.order_asc ? toBoolean(this.$route.query.order_asc) : this.default.orderDirAsc;
                },
                set(value) {
                    this.$router.push({
                        query: {
                            ...this.$route.query,
                            order_asc: value.toString()
                        }
                    });
                    this.filter();
                }
            }
        },
        mounted() {
            this.getTenders();
        }
    };
</script>

<style scoped>

</style>
