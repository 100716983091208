<template>
    <div class="sort">
        <p class="sort-title">Сортировать по:</p>
        <div class="sort-link"
             @click="setOrder(value)"
             :class="{active:order===value&&!orderDirAsc,'ion-a-arrow-up':order===value}"
             v-for="(label,value) in list">{{label}}
        </div>
    </div>
</template>

<script>
    export default {
        name:    "sort",
        props:   {
            order:       {
                type:    String,
                default: 'created_at'
            },
            orderDirAsc: {
                type:    Boolean,
                default: false
            },
            list:        Object,
        },
        data() {
            return {};
        },
        methods: {
            setOrder(value) {
                this.$log.debug(this.order === value, this.orderDirAsc, {
                    order:       value,
                    orderDirAsc: this.order === value ? !this.orderDirAsc : false
                });
                this.$emit('changeOrder', {
                    order:       value,
                    orderDirAsc: this.order === value ? !this.orderDirAsc : false
                });
            }
        }
    };
</script>

<style scoped>

</style>
