import errors     from '../helpers/errors';
import Vue        from "vue";
import apiService from '../services/api';
import flashService from '../services/flashMsg';

export default function () {

    return new Vue({
        el:      '#app-calls',
        data:    {
            phone: '',
            error: {
                phone: ''
            },
            ajax:  false,
        },
        watch:   {
            phone: function () {
                this.error.phone = '';
            },
        },
        methods: {
            send() {
                let _this = this;

                if (this.validate() && !this.ajax) {
                    this.ajax = true;
                    apiService.send('calls', {phone: this.phone.replace(/[^0-9.]/g, ""),}).then(data => {
                        _this.ajax  = false;
                        _this.phone = '';
                        $('#requestForConsultationSent').addClass('open');
                    }).catch(err => {
                        console.error(err);
                        if (err.hasOwnProperty('responseJSON')) {
                            _this.error.phone = err.responseJSON.error;
                        }
                        _this.ajax = false;
                    });
                }
            },
            validate() {
                let error = false;

                if (!this.phone) {
                    error            = true;
                    this.error.phone = errors.empty;
                }
                else {
                    if (this.phone.replace(/[^0-9.]/g, "").length < 11) {
                        error            = true;
                        this.error.phone = errors.phone;
                    }
                }

                return !error;
            }
        }

    });
}
