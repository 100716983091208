<template>
    <div class="createTend-tabsCaption mrb80">
        <div
            class="createTend-wrap"
            v-for="(item,key) in list">
            <a href="#"
               class="createTend-link rm"
               @click.prevent.stop="setActive(key)"
               :class="{active:key===step, 'no-active': key < step}">{{key+1}}. {{item.name}}</a>
            <span v-if="key+1!==list.length" class="ion-chevron-right"></span>
        </div>
    </div>
</template>

<script>
    export default {
        name:     "tabHeader",
        data:     function () {
            return {};
        },
        computed: {
            step: function () {
                return this.list.findIndex(e => {
                    return e.active;
                });
            }
        },
        props:    {
            list: Array,
        },
        methods:  {
            setActive(key) {
                this.$emit('changeStep', key);
            }
        }
    };
</script>


