export default {
    empty:                 'не может быть пустым',
    password_only:         'в пароле должны использовать только символы ". , /"',
    password_confirmation: 'пароли несовпадают',
    email:                 'некорректный email адрес',
    phone:                 'некорректный номер телефона',
    captcha:               'CAPTCHA не пройдена',
    login:                 'не верный e-mail или пароль',
    activate_code:         'некорректный код',
    site:                  'некорректный cайт',
    require:               'обязательное поле',
    current_password:      'некорректный Текущий пароль',
    lessThen:              'Должно быть меньше, чем до',
    moreThen:              'Должно быть больше, чем от',
    ogrn:                  'Должен быть от 13 до 15 цифр',
    inn:                   'Должен быть от 10 до 12 цифр',
    settlement_account:    'Должен быть от 20 до 25 цифр',
};
