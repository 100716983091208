const prupal = (num, words, withWords = 1) => {
    let numAbs = Math.abs(num),
        cases = [2, 0, 1, 1, 1, 2],
        result = '';
    withWords === 1 ? result += num + ' ' : false;
    result += words[(numAbs % 100 > 4 && numAbs % 100 < 20) ? 2 : cases[(numAbs % 10 < 5) ? numAbs % 10 : 5]];

    return result;
};

export default prupal;
