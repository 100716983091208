export default {
    'timeZone':       {
        type:  'GET',
        'url': '/api/getTimeZoneByCity'
    },
    'sub_industries': {
        type: 'GET',
        url:  '/api/sub_industries'
    },
    'countries':      {
        type: 'GET',
        url:  '/api/countries'
    },
    'city':           {
        type: 'GET',
        url:  '/api/country_cities'
    },
    'timezones':      {
        type: 'GET',
        url:  '/api/timezones'
    }
};
