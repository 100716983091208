import errors         from '../../helpers/errors';
import Vue            from "vue";
import apiService     from '../../services/api';

export default function () {
    return new Vue({
        el: '#app-payment',
        data: {
            render: true,
            edit: {
                requisites: false,
                cards: false
            },
            ajax: {
                requisites: false,
                cards: false
            },
            isCustomer: isCustomer,
            requisites: userRequsites,
            error: {
                requisites: {
                    official_name: '',
                    ogrn: '',
                    inn: '',
                    settlement_account: ''
                }
            }
        },
        methods: {
            changeOfficialName: function($event) {
                console.log($event.target.value);
                this.error.requisites.official_name=''
            },
            editBlock: function (block, status) {
                this.edit[block] = status ? status : false;
            },
            validate: function () {
                let error = false;

                if (!this.requisites.official_name) {
                    this.error.requisites.official_name = errors.empty;
                    error = true;
                }

                if (this.requisites.ogrn) {
                    let ogrnSize = this.requisites.ogrn.toString().length;
                    if (ogrnSize > 15 || ogrnSize < 13) {
                        this.error.requisites.ogrn = errors.ogrn;
                        error = true;
                    }
                }

                if (this.requisites.inn) {
                    let innSize = this.requisites.inn.toString().length;
                    if (innSize > 12 || innSize < 10) {
                        this.error.requisites.inn = errors.inn;
                        error = true;
                    }
                }

                if (this.requisites.settlement_account) {
                    let settlement_accountSize = this.requisites.settlement_account.toString().length;
                    if (settlement_accountSize > 25 || settlement_accountSize < 20) {
                        this.error.requisites.settlement_account = errors.settlement_account;
                        error = true;
                    }
                }

                return !error;
            },
            updateRequisites: function () {
                let _this = this;

                if (!this.ajax.requisites && this.validate()) {
                    this.ajax.requisites = true;
                    apiService.send('update-requisites', this.requisites).then(function (data) {
                        _this.editBlock('requisites');
                        _this.ajax.requisites = false;
                    }).catch(function (err) {
                        _this.ajax.requisites = false;
                    });
                }
            }
        }
    });
}
