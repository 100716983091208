import Vue from "vue";
import PortfolioComponent from './portfolio.vue';

export default function () {
    return new Vue({
        el: '#app-portfolio',
        template: `
<section id="app-portfolio" class="section app-element">
    <portfolio></portfolio>
</section>
        `,
        components: {
            'portfolio': PortfolioComponent
        }
    });
}
