<template>
    <div class="belay-wrap">

        <div :class="{belay:template==='sidebar'}">
            <div v-if="template==='sidebar'" class="row-wrap jc-start">
                <div v-for="service in services" class="col-t5 col-p10">
                    <div class="col-v10 mrb8">
                        <div class="check">
                            <input type="checkbox"
                                   :id="'service'+service.id"
                                   :checked="checked.indexOf(service.id)>-1"
                                   @change="changeChecked(service.id)"
                            >
                            <span v-if="service.notify" class="ion-ios-help-outline">
                                <span class="helpOutline-open">
                                    <span class="helpOutline-open-text">
                                        <p>{{ service.notify }}</p>
                                    </span>
                                </span>
                            </span>
                            <label :for="'service'+service.id"
                                   class="check-lb ">{{service.name}}
                                <span class="ion-a-done"></span>
                                <span class="forkPrice-hover"
                                      v-if="service.notify">
                                            <span class="forkPrice-layer">{{service.notify}}
                                            </span>
                                        </span>
                            </label>
                        </div>
                    </div>
                    <div class="belay-text fs12 grey">
                        <nl2br v-if="service.description"
                               tag="p"
                               class="grey fs12 mrb8"
                               :text="service.description"/>
                        <p v-for="addText in service.additional"
                           class="grey fs12"><span class="ion-checkmark fs12 mrr6 green"></span>{{addText.text}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div :class="{'col belay':template==='sidebar'}">
            <div class="col-v10 sp-ard">
                <p class="white fs12">Итого</p>
                <div class="belay-price rb">
                    <span class="white fs28 rb">{{totalPrice}}</span>
                    <span class="white fs28 rb">руб.</span>
                </div>
            </div>
        </div>

        <div  v-if="step === 4 && !paymentModal">
            <div class="jc-end col-v10 mrb8 mobBtn">
                <a @click.stop.prevent="$emit('changeStep',2)"
                   class="grey prevMob rm cancel mrb24">Назад</a>
                <div @click="$emit('submit')" class="create btn colorGreen btn375">
                    <span class="colorGreen-hover"></span>
                    <span class="zi">{{ totalPrice ? 'Оплатить и отправить на модерацию' : 'Отправить на модерацию' }}</span>
                </div>
            </div>
            <div  class="col-v10 jc-end tx-rig prwMob">
                <div class="grey fs12">Нажимая кнопку
                    {{ totalPrice ? '“Оплатить и отправить на модерацию”' : '"Отправить на модерацию"' }}, вы даете <a
                            href="/privacy"
                            target="_blank"
                            class="rb grey fs12">согласие на обработку персональных данных</a>
                    <a href="/personal-data" target="_blank" class="rb grey fs12">и принимаете условия Пользовательского
                        соглашения</a>
                </div>
            </div>
        </div>
        <div  v-if="step === 4 && paymentModal">
            <div class="jc-end col-v10 mrb8 mobBtn">
                <a href="/person" class="create btn colorGreen btn375">
                    <span class="colorGreen-hover"></span>
                    <span class="zi">В профиль</span>
                </a>
            </div>
        </div>
    </div>


</template>

<script>
    import store from '../../../store';
    import {
        mapState,
        mapActions,
        mapMutations,
        mapGetters,
    } from 'vuex';
    import Nl2br from 'vue-nl2br';

    export default {
        name: "optionService",
        props: {
            created: {
                type: Boolean,
                default: false
            },
            paymentModal: {
                type: Boolean,
                default: false
            },
            step: {
                default: false
            },
            template: {
                type: String,
                default: 'sidebar'
            }
        },
        data() {
            return {};
        },
        store,
        computed: {
            totalPrice() {
                return this.$store.getters['optionService/totalPrice'];
            },
            ...mapState('optionService', [
                'services',
                'checked'
            ]), ...mapGetters('optionService', ['totalPrice'])
        },
        methods: {
            ...mapActions('optionService', [
                'setOptionServices'
            ]), ...mapMutations('optionService', [
                'changeChecked'
            ])
        },
        components: {
            Nl2br,
        },
        mounted() {
            this.setOptionServices();
        }
    };
</script>

<style scoped>

</style>
