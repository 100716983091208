<template>
    <div>
        <div class="board totalInf inpBxSh myCompany">
            <div class="board-title">
                <p class="rm">Пароль</p>
            </div>
            <div class="board-inf">
                <div class="col-v10">
                    <div class="col-v5 col-p10 pr0">
                        <div class="inpWidth mrb24">
                            <div class="inp inpEmail disabled"
                                 :class="{error:error.current_password}">
                                <p class="inpName">Текущий пароль</p>
                                <input :type="type.current_password"
                                       v-model="current_password"
                                       ref="current_password"
                                       @input="error.current_password=''"
                                       placeholder="Текущий пароль">
                                <span @click="switchVisibility('current_password')"
                                      class="show-eye"
                                      :class="{'ion-a-eye-off':type.current_password==='password','ion-a-eye-on':type.current_password==='text'}"></span>
                                <span class="err ion-a-close"></span>
                                <p class="inpErr">{{ error.current_password }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-v10">
                    <div class="col-v5 col-p10 pr0">
                        <div class="inpWidth mrb24">
                            <div class="inp inpEmail disabled"
                                 :class="{error:error.new_password}">
                                <p class="inpName">Новый пароль</p>
                                <input :type="type.new_password"
                                       ref="new_password"
                                       v-model="new_password"
                                       @input="error.new_password=''"
                                       placeholder="Новый пароль">
                                <span @click="switchVisibility('new_password')"
                                      class="show-eye"
                                      :class="{'ion-a-eye-off':type.new_password==='password','ion-a-eye-on':type.new_password==='text'}"></span>
                                <span class="err ion-a-close"></span>
                                <p class="inpErr">{{ error.new_password }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-v10">
                    <div class="col-v5 col-p10 pr0">
                        <div class="inpWidth mrb24">
                            <div class="inp inpEmail disabled"
                                 :class="{error:error.repeat_new_password}">
                                <p class="inpName">Подтвердите пароль</p>
                                <input :type="type.repeat_new_password"
                                       v-model="repeat_new_password"
                                       @input="error.repeat_new_password=''"
                                       ref="repeat_new_password"
                                       placeholder="Подтвердите пароль">
                                <span @click="switchVisibility('repeat_new_password')"
                                      class="show-eye"
                                      :class="{'ion-a-eye-off':type.repeat_new_password==='password','ion-a-eye-on':type.repeat_new_password==='text'}"></span>
                                <span class="err ion-a-close"></span>
                                <p class="inpErr">{{ error.repeat_new_password }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="jc-start col-v10">
                    <a href="javascript:void(0)"
                       @click="clearPasswordFields"
                       class="green rm cancel">Отмена</a>
                    <div class="create btn colorGreen btn148"
                         @click="changePassword">
                        <div v-if="ajax"
                             class="spinner-btn">
                            <div class="bounce1"></div>
                            <div class="bounce2"></div>
                            <div class="bounce3"></div>
                        </div>
                        <span class="colorGreen-hover">
                            </span>
                        <span v-if="!ajax"
                              class="zi">Сохранить</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="board" v-if="false">
            <div class="board-title">
                <p class="rm">Уведомления</p>
                <span class="ion-a-create"></span>
            </div>
            <div class="board-inf">
                <div class="jc-start">
                    <div class="col-v10 mrb24 lh1">
                        <div class="check">
                            <input type="checkbox"
                                   id="chd1"
                                   name="ch"
                                   disabled="disabled"
                                   checked="checked">
                            <label for="chd1"
                                   class="check-lb ">о новых тендерах раз в
                                неделю<span class="ion-a-done"></span></label>
                        </div>
                    </div>
                    <div class="col-v10 mrb24 lh1">
                        <div class="check">
                            <input type="checkbox"
                                   id="chd2"
                                   name="ch"
                                   disabled="disabled">
                            <label for="chd2"
                                   class="check-lb ">о новых тендерах сразу, как они публикуются на
                                сайте<span class="ion-a-done"></span></label>
                        </div>
                    </div>
                    <div class="col-v10 mrb24 lh1">
                        <div class="check">
                            <input type="checkbox"
                                   id="chd3"
                                   name="ch"
                                   disabled="disabled"
                                   checked="checked">
                            <label for="chd3"
                                   class="check-lb ">только о новых тендерах по отмеченым в профиле
                                специализациям<span class="ion-a-done"></span></label>
                        </div>
                    </div>
                    <div class="col-v10 mrb24 lh1">
                        <div class="check">
                            <input type="checkbox"
                                   id="chd4"
                                   name="ch"
                                   checked="checked"
                                   disabled="disabled">
                            <label for="chd4"
                                   class="check-lb ">комментарии к моим
                                тендерам<span class="ion-a-done"></span></label>
                        </div>
                    </div>


                    <div class="col-v10 mrb24 lh1">
                        <div class="check">
                            <input type="checkbox"
                                   id="chd5"
                                   name="ch"
                                   disabled="disabled">
                            <label for="chd5"
                                   class="check-lb ">приглашения на участие
                                тендере<span class="ion-a-done"></span></label>
                        </div>
                    </div>
                    <div class="col-v10 lh1">
                        <div class="check">
                            <input type="checkbox"
                                   id="chd6"
                                   name="ch"
                                   checked="checked"
                                   disabled="disabled">
                            <label for="chd6"
                                   class="check-lb ">Уведомлять о новых статьях в
                                блоге<span class="ion-a-done"></span></label>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import errors     from '../../helpers/errors';
    import apiService from '../../services/api';

    export default {
        name: "options",
        data() {
            return {
                ajax: false,
                render: true,
                current_password: '',
                new_password: '',
                repeat_new_password: '',
                error: {
                    current_password: '',
                    new_password: '',
                    repeat_new_password: ''
                },
                type: {
                    current_password: 'password',
                    new_password: 'password',
                    repeat_new_password: 'password'
                }
            };
        },
        methods: {
            switchVisibility(type) {
                this.type[type] = this.type[type] === 'password' ? 'text' : 'password';
                this.$refs[type].focus();
            },
            clearPasswordFields: function () {
                this.current_password = '';
                this.new_password = '';
                this.repeat_new_password = '';
            },
            changePassword: function () {
                let _this = this;
                if (!this.ajax && this.validate()) {
                    this.ajax = true;
                    apiService.send('password-reset', {
                        current_password: this.current_password,
                        new_password: this.new_password,
                        repeat_new_password: this.repeat_new_password
                    }).then(function (data) {
                        _this.clearPasswordFields();
                        _this.ajax = false;
                    }).catch(function (err) {
                        _this.ajax = false;
                        if (err.hasOwnProperty('responseJSON')) {
                            let keys = Object.keys(err.responseJSON.error);
                            keys.forEach(function (e) {
                                _this.error[e] = err.responseJSON.error[e];
                            });
                        }
                    });
                }
            },
            validate: function () {
                let error = false;

                if (!this.current_password) {
                    this.error.current_password = errors.empty;
                    error = true;
                }

                if (!this.new_password) {
                    this.error.current_password = errors.empty;
                    error = true;
                }

                if (!this.repeat_new_password) {
                    this.error.current_password = errors.empty;
                    error = true;
                } else if (this.repeat_new_password !== this.new_password) {
                    error = true;
                    this.error.repeat_new_password = errors.password_confirmation;
                }

                return !error;
            }
        }
    };
</script>

<style scoped>

</style>
