<template>
    <div>
        <div v-if="step!==4"
             class="createTend-tabs">
            <tabHeader
                    :list="tabList"
                    @changeStep="changeStep"
            ></tabHeader>
            <div class="col-v10 jc-st-al-st">
                <tabContent
                        :list="tabList"
                        @changeStep="changeStep"
                >
                    <step1 v-if="step===1"></step1>
                    <step2 v-if="step===2"></step2>
                    <step3 v-if="step===3"></step3>
                </tabContent>

                <totals></totals>
            </div>
        </div>
        <div v-if="step===4"
             class="createTend-tabs">
            <div class="col-v10 jc-st-al-st">
                <previewStep
                        :created="created"
                        :paymentModal="paymentModal"
                        @submit="createTender"
                        @changeStep="changeStep"></previewStep>
                <totals :step="step" :paymentModal="paymentModal" :created="created" @changeStep="changeStep" @submit="createTender"></totals>
            </div>
        </div>
    </div>
</template>

<script>
    import tabHeader     from '../../tab/header';
    import tabContent    from '../../tab/content';
    import step1         from './steps/step1';
    import step2         from './steps/step2';
    import step3         from './steps/step3';
    import preview       from './steps/preview';
    import optionService from './optionService';
    import store         from '../../../store';
    import {mapActions}  from 'vuex';

    export default {
        name: "createTender",
        preview: false,
        data: function () {
            return {
                tabList: [
                    {
                        name: 'О тендере',
                        active: false,
                        nextTitle: 'Далее'
                    },
                    {
                        name: 'Требования к исполнителю',
                        active: false,
                        nextTitle: 'Далее'
                    },
                    {
                        name: 'О Вас',
                        active: false,
                        nextTitle: 'Предварительный просмотр'
                    }
                ]
            };
        },
        store,
        computed: {
            step: {
                get() {
                    let step = this.$store.state.tender.step;
                    if (this.tabList.hasOwnProperty(step - 1)) {
                        this.tabList[step - 1].active = true;
                    }
                    return step;
                },
                set(value) {
                    this.$store.commit('tender/setByKey', {
                        key: 'step',
                        value: value
                    });
                }
            },
            created() {
                return this.$store.getters['tender/created'];
            },
            createdId() {
                return this.$store.getters['tender/getCreatedId'];
            },
            paymentModal() {
                console.log(this.$store.getters['tender/getPaymentModal']);
                return this.$store.getters['tender/getPaymentModal'];
            }
        },
        components: {
            tabHeader: tabHeader,
            tabContent: tabContent,
            step1: step1,
            step2: step2,
            step3: step3,
            previewStep: preview,
            totals: optionService
        },
        methods: {
            changeStep(key) {
                if (this.step < key + 1) {
                    this.$store.dispatch('tender/validate', this.step);
                    this.$log.debug(this.$store.getters['tender/errorInStep'](this.step));
                    if (this.$store.getters['tender/errorInStep'](this.step)) return;
                }

                if (this.tabList.hasOwnProperty(key)) {
                    if (this.tabList.hasOwnProperty(this.step - 1)) this.tabList[this.step - 1].active = false;
                    this.tabList[key].active = true;
                }

                this.step = key + 1;

                // let elem = this.step === 4 ? '#app-create-tender' : '.createTend-tabsContent';
                animateToElement('#app-create-tender');
            }, ...mapActions('tender', ['createTender'])
        },
        mounted() {
            getParameterByName('s') ? this.$store.commit('tender/setService_id', getParameterByName('s')) : null;
        }
    };
</script>

<style scoped>

</style>
