import Vue                      from "vue";
import RestorePasswordComponent from './restorePassword.vue';

export default function () {

    return new Vue({
        el: '#vue-lose-pass-app',
        template: `
<section class="ctrBox app-element" id="vue-lose-pass-app">
    <restore-password></restore-password>
</section>
        `,
        components: {
            'restore-password': RestorePasswordComponent
        }
    });
}
