import methods from '../helpers/apiMethods';

/**
 * Class Api
 */
class Api {

    constructor() {

        /**
         * Api authentication token invalidate
         * @type {boolean}
         */
        this.invalidate = false;

        /**
         * Receive token status
         * @type {boolean}
         */
        this.newToken = false;

        /**
         * Http api methods
         */
        this.methods = methods;

    }

    /**
     * Request to api server
     * @param method
     * @param data
     */
    send(method, data) {
        return new Promise((resolve, reject) => {
            let _this      = this,
                before     = function () {
                },
                methodData = this.getMethodOptions(method),
                options    = {
                    dataType: 'json',
                    url:      methodData.url,
                    type:     methodData.type,
                    success:  (data) => {
                        resolve(data);
                    },
                    error:    (data) => {
                        if (data.hasOwnProperty('responseJSON') && data.responseJSON.hasOwnProperty('error') && data.responseJSON.error === 'token_expired') {
                            this.getNewApiToken((token, error) => {
                                if (error) return reject(data);

                                before();
                            });
                        }
                        else {
                            reject(data);
                        }
                    }
                };

            before = function () {
                _this.getApiToken((token, error) => {
                    if (error) {
                        console.error(error);
                    }
                    if (token) {
                        options.beforeSend = function (xhr) {
                            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                        };
                    }

                    if (data) {
                        options.data = data;

                        if (data instanceof FormData) {
                            options.contentType = false;
                            options.processData = false;
                        }
                    }

                    $.ajax(options);
                });
            };

            before();
        });
    }

    /**
     * Get api token
     * @param next
     * @returns {*}
     */
    getApiToken(next) {
        let token = getCookie('api_token');

        if (this.newToken) {
            return next(null, null);
        }

        if (!token) {
            return this.getNewApiToken(next);
        }

        return next(token);
    }

    /**
     * Create new api token by authenticate user
     * @param next
     */
    getNewApiToken(next) {
        this.newToken = true;
        this.send('api-token').then((data) => {
            this.newToken = false;
            this.setApiToken(data.token);
            next(data.token, null);
        }).catch((error) => {
            next(null, error);
        });
    }

    /**
     * Refresh api token
     */
    refreshApiToken() {
        this.getApiToken((token, error) => {
            if (!token || error) return;

            this.send('refresh', {token: token}).then((data) => {
                this.setApiToken(data.token);
            }).catch((error) => {
                console.error(error);
            });
        });
    }

    /**
     * Set token to browser cookie
     * @param token
     */
    setApiToken(token) {
        setCookie('api_token', token, {
            // expires: 60 * 60 * 2,
            path: '/'
        });
    }

    /**
     * Get http api method options
     * @param method
     * @returns {*}
     */
    getMethodOptions(method) {
        if (!this.methods.hasOwnProperty(method)) {
            throw new Error('Method not found');
        }

        return this.methods[method];
    }

    /**
     * Set token to invalidate
     * @param token
     * @returns {*}
     */
    invalidateApiToken(token) {
        this.send('invalidate', {token: token}).then((data) => {
            console.log(data);
            this.invalidate = true;
        }).catch((error) => {
            console.error(error);
        });
        return token;
    }
}

export default new Api();
