import err from '../../helpers/errors';
import Vue from 'vue';
import api from '../../services/api';
import flash from '../../services/flashMsg';
import isUrl from 'validator/lib/isURL';

const stepFields = {
    1: {
        name: ['require'],
        start_date: [
            'require',
            'date'
        ],
        end_date: [
            'require',
            'date'
        ],
        quickly_time: ['time'],
        industry: ['require'],
        sub_industry: ['require'],
        tender_portal: ['url'],
        description: ['require'],
    },
    2: {
        min_price: [
            'require'
            // 'lessThen|max_price'
        ]
        // max_price: [
        //     'require',
        //     'moreThen|min_price'
        // ]
    },
    3: {
        'userData.companyName': ['require|userData.legal'],
        'userData.firstname': ['require|!userData.legal'],
        'userData.surname': ['require|!userData.legal'],
        'userData.inn': [
            'inn'
        ],
        'userData.isPremium': [
            'isPremium'
        ],
        'userData.site': [
            'site'
        ],
        'userData.ogrn': [
            'ogrn'
        ],
        'userData.settlement_account': [
            'settlement_account'
        ],
        'userData.email': [
            'require|userData.contactMain',
            'email'
        ],
        'userData.phone': [
            'require|userData.contactMain',
            'phone'
        ],
        'userData.contact.email': [
            'require|!userData.contactMain',
            'email'
        ],
        'userData.contact.phone': [
            'require|!userData.contactMain',
            'phone'
        ],
    }
};

// initial state
const state = {
    name: '',
    step: 1,
    description: '',
    max_price: '',
    min_price: '',
    payment_modal: false,
    currency_id: null,
    tender_portal: '',
    start_date: null,
    end_date: null,
    service_id: null,
    quickly: false,
    quickly_time: null,
    performer_type: 0,
    country_id: 165,
    city_id: null,
    questions: [],
    allow_view: 1,
    skills: '',
    industry: null,
    sub_industry: null,
    id: null,
    files: [],
    ajaxFiles: false,
    userData: {
        companyName: '',
        firstname: '',
        surname: '',
        site: '',
        country_id: null,
        city_id: null,
        inn: '',
        ogrn: '',
        isPremium: false,
        settlement_account: '',
        contactMain: 1,
        showContactsWinner: 0,
        email: '',
        phone: '',
        legal: 0,
        contact: {
            id: null,
            email: '',
            phone: '',
        },
    },
    errors: {
        name: '',
        description: '',
        max_price: '',
        min_price: '',
        currency_id: '',
        tender_portal: '',
        start_date: '',
        end_date: '',
        service_id: '',
        quickly: '',
        quickly_time: '',
        safely: '',
        insurance: '',
        performer_type: '',
        country: '',
        city: '',
        questions: '',
        allow_view: '',
        skills: '',
        industry: '',
        sub_industry: '',
        files: '',
        userData: {
            companyName: '',
            firstname: '',
            is_premium: '',
            surname: '',
            legal: '',
            site: '',
            country_id: '',
            city_id: '',
            inn: '',
            ogrn: '',
            settlement_account: '',
            contactMain: '',
            showContactsWinner: '',
            email: '',
            phone: '',
            contact: {
                email: '',
                phone: '',
            },
        }
    }
};

// getters
const getters = {
    questions(state) {
        return state.questions.filter(e => !!e.text);
    },
    created: state => {
        return !!state.id;
    },
    getCreatedId: state => {
        return state.id ? state.id : null;
    },
    getPaymentModal: state => {
        return state.payment_modal;
    },
    errorInStep: state => step => {
        return Object.keys(stepFields[step]).filter(i => !!i.split('.').reduce((o, i) => o[i], state.errors)).length;
    },
    getSiteUrl: state => {
        if (state.tender_portal) {
            return state.tender_portal;
        }

        return;
    },
    getUserSiteUrl: state => {
        if (state.userData.site) {
            return state.userData.site;
        }

        return;
    },
    getContactEmail: state => {
        return state.userData.contactMain ? state.userData.email : state.userData.contact.email;
    },
    getContactPhone: state => {
        return state.userData.contactMain ? state.userData.phone : state.userData.contact.phone;
    },
    getValueByKey: state => key => {
        let value = key.replace('!', '').split('.').reduce((o, i) => o[i], state);
        return key.indexOf('!') > -1 ? !value : value;
    },
    middlePrice(state) {
        let minPrice = state.min_price ? state.min_price : 0;
        // let maxPrice = state.max_price ? state.max_price : 0;
        return minPrice;
    },
};

// actions
const actions = {
    addFiles({state, commit}, files) {
        commit('ajaxFiles', true);
        let fd = new FormData();
        files.forEach(function (e) {
            fd.append('files[]', e);
        });
        api.send('uploadTmp', fd).then(data => {
            if (data.hasOwnProperty('error')) {
                flash(data.error, 'error');
            }
            else if (data.hasOwnProperty('success')) {
                commit('addFiles', data.files);
            }
        }).catch(err => Vue.$log.error(err))
            .finally(e => {
                commit('ajaxFiles', false);
            });
    },
    removeFile({state, commit}, key) {
        api.send('removeTmp', {file_id: state.files[key].id}).then(data => Vue.$log.debug(data)).catch(err => Vue.$log.error(err));
        commit('removeFile', key);
    },
    validate({state, commit, getters}, step) {
        Vue.$log.debug('step', step);
        for (let i of Object.keys(stepFields[step])) {
            //Пропускаем поля без валидиции
            if (!stepFields[step][i]) return;

            let e = stepFields[step][i],
                value = getters['getValueByKey'](i),
                requireIndex = e.findIndex(r => r.includes('require')),
                lessIndex = e.findIndex(r => r.includes('lessThen')),
                moreIndex = e.findIndex(r => r.includes('moreThen')),
                phoneIndex = e.findIndex(r => r.includes('phone')),
                isPremiumIndex = e.findIndex(r => r.includes('isPremium')),
                emailIndex = e.findIndex(r => r.includes('email')),
                ogrnIndex = e.findIndex(r => r.includes('ogrn')),
                settlement_accountIndex = e.findIndex(r => r.includes('settlement_account')),
                innIndex = e.findIndex(r => r.includes('inn'));

            Vue.$log.debug(i, e, value);
            //Проверяем на наличие значения
            if (requireIndex > -1 && !value) {
                let requireWhen = e[requireIndex].split('|');
                if ((requireWhen.length > 1 && getters['getValueByKey'](requireWhen[1])) || requireWhen.length === 1) {
                    commit('setErrorByKey', {
                        key: i,
                        value: err.require
                    });
                    return;
                }
            }

            //проверка на телефон
            if (phoneIndex > -1 && value) {
                if (value.replace(/[^0-9.]/g, "").length < 11) {
                    commit('setErrorByKey', {
                        key: i,
                        value: err.phone
                    });
                    return;
                }
            }

            //Проверка на email
            if (emailIndex > -1 && value) {
                if (!validateEmail(value)) {
                    commit('setErrorByKey', {
                        key: i,
                        value: err.email
                    });
                    return;
                }
            }


            //Проверяем на правила "больше чем, меньше чем"
            if (lessIndex > -1) {
                let lessField = e[lessIndex].split('|')[1];
                if (value > state[lessField]) {
                    commit('setErrorByKey', {
                        key: i,
                        value: err.lessThen
                    });
                    return;
                }
            }
            if (moreIndex > -1) {
                let moreField = e[moreIndex].split('|')[1];
                if (value < state[moreField]) {
                    commit('setErrorByKey', {
                        key: i,
                        value: err.moreThen
                    });
                    return;
                }
            }

            if (ogrnIndex > -1) {
                if (value) {
                    let ogrnSize = value.toString().length;
                    if (ogrnSize > 15 || ogrnSize < 13) {
                        commit('setErrorByKey', {
                            key: i,
                            value: errors.ogrn
                        });
                        return;
                    }
                }
            }

            if (innIndex > -1) {
                if (value) {
                    let innSize = value.toString().length;
                    if (innSize > 12 || innSize < 10) {
                        commit('setErrorByKey', {
                            key: i,
                            value: errors.inn
                        });
                        return;
                    }
                }
            }

            if (settlement_accountIndex > -1) {
                if (value) {
                    let settlement_accountSize = value.toString().length;
                    if (settlement_accountSize > 25 || settlement_accountSize < 20) {
                        commit('setErrorByKey', {
                            key: i,
                            value: errors.settlement_account
                        });
                        return;
                    }
                }
            }
        }
    },
    setUserDataDefaults({state, commit}, {personal, requsites}) {
        let userData = state.userData,
            keys = Object.keys(userData),
            reqFields = [
                'inn',
                'ogrn',
                'settlement_account',
            ],
            nonDefaultFields = [
                'contactMain',
                'contact',
                'showContactsWinner',
            ], otherFields = [
                'isPremium'
            ];

        for (let key of keys) {
            if (!userData[key]) {
                let value = '';
                if (reqFields.indexOf(key) > -1) {
                    value = requsites[key];
                }
                else if (nonDefaultFields.indexOf(key) === -1) {
                    value = personal[key];
                } else if (otherFields.indexOf(key) > -1) {
                    value = otherFields[key];
                }
                commit('setUserDataByKey', {
                    key: key,
                    value: value
                });
            }
        }
    },
    createTender({state, commit, rootState}) {
        let tender = JSON.parse(JSON.stringify(state));

        delete tender.errors;

        if (tender.userData.contactMain) delete tender.userData.contact;

        tender.optionService = rootState.optionService.checked;
        tender.start_date = new Date(tender.start_date).toMysqlFormat();
        tender.end_date = new Date(tender.end_date).toMysqlFormat();
        tender.questions = getters.questions(state);

        Vue.$log.debug(tender);

        api.send('createTender', tender).then(data => {
            commit('setId', data.id);
            if (data.hasOwnProperty('refillPrice')) {
                $('#refillWalletForTender').addClass('open').find('input[name="price"]').val(data.refillPrice);
                $('#refillWalletForTender').find('input[name="id"]').val(data.id);
            } else {
                commit('setPaymentModal', true);
            }
        }).catch(function (data) {
            if (data.hasOwnProperty('responseJSON')) {
                flash.add(data.responseJSON.error, 'error');
            }
        });
    }
};

// mutations
const mutations = {
    setId(state, value) {
        state.id = value;
    },
    setPaymentModal(state, value) {
        state.payment_modal = value;
    },
    setName(state, value) {
        state.name = value;
        state.errors['name'] = '';
    },
    setDescription(state, value) {
        state.description = value;
        state.errors['description'] = '';
    },
    setMax_price(state, value) {
        state.max_price = value ? parseFloat(value) : '';
        state.errors['max_price'] = '';
    },
    setMin_price(state, value) {
        state.min_price = value ? parseInt(value) : '';
        state.errors['min_price'] = '';
    },
    setCurrency(state, value) {
        state.currency_id = value;
        state.errors['currency'] = '';
    },
    setTender_portal(state, value) {
        let error = '';
        if (value) {
            if (!isUrl(value)) {
                error = 'невалидный адрес';
            } else if (!value.includes('http')) {
                value = 'http://' + value;
            }
        }
        state.tender_portal = value;
        state.errors['tender_portal'] = error;
    },
    setStart_date(state, value) {
        state.start_date = value;
        state.errors['start_date'] = '';
    },
    setEnd_date(state, value) {
        state.end_date = value;
        state.errors['end_date'] = '';
    },
    setService_id(state, value) {
        state.service_id = value;
        state.errors['service_id'] = '';
    },
    setQuickly(state, value) {
        state.quickly = value;
        state.errors['quickly'] = '';
    },
    setQuickly_time(state, value) {
        state.quickly_time = value;
        state.errors['quickly_time'] = '';
    },
    setSafely(state, value) {
        state.safely = value;
        state.errors['safely'] = '';
    },
    setInsurance(state, value) {
        state.insurance = value;
        state.errors['insurance'] = '';
    },
    setPerformer_type(state, value) {
        state.performer_type = value;
        state.errors['performer_type'] = '';
    },
    setCountry(state, value) {
        state.country_id = value;
        state.errors['country'] = '';
    },
    setCity(state, value) {
        state.city_id = value;
        state.errors['city'] = '';
    },
    setQuestions(state, value) {
        state.questions = value;
        state.errors['questions'] = '';
    },
    setQuestion(state, {key, value}) {
        state.questions[key].text = value;
        state.errors['questions'] = '';
    },
    removeQuestion(state, key) {
        state.questions = state.questions.filter((e, i) => i !== key);
        state.errors['questions'] = '';
    },
    addQuestion(state) {
        state.questions.push({text: ''});
        // state.questions           = questions;
        state.errors['questions'] = '';
    },
    setAllow_view(state, value) {
        state.allow_view = value;
        state.errors['allow_view'] = '';
    },
    setSkills(state, value) {
        state.skills = value;
        state.errors['skills'] = '';
    },
    setIndustry(state, value) {
        state.industry = value;
        state.errors['industry'] = '';
    },
    setSub_industry(state, value) {
        state.sub_industry = value;
        state.errors['sub_industry'] = '';
    },
    setFiles(state, value) {
        state.files = value;
        state.errors['files'] = '';
    },
    addFiles(state, value) {
        state.files = state.files.concat(value);
    },
    ajaxFiles(state, value) {
        state.ajaxFiles = value;
    },
    removeFile(state, key) {
        state.files = state.files.filter((e, i) => i !== key);
    },
    setErrors(state, value) {
        state.errors = value;
    },
    setErrorByKey(state, {key, value}) {
        setter(state.errors, key, value);
    },
    unsetError(state, key) {
        state.errors[key] = '';
    },
    setUserData(state, value) {
        state.userData = value;
    },
    setUserDataByKey(state, {key, value}) {
        let error = '';
        if (key === 'site') {
            if (value) {
                if (!isUrl(value)) {
                    error = 'невалидный адрес';
                } else if (!value.includes('http')) {
                    value = 'http://' + value;
                }
            }
        }
        state.userData[key] = value;
        state.errors.userData[key] = key === 'contact' ? {
            email: '',
            phone: ''
        } : error;
    },
    setByKey(state, {key, value}) {
        state[key] = value;
    },
    setUserDataContact(state, {key, value}) {
        state.userData['contact'][key] = value;
        state.errors.userData.contact[key] = '';
    }
};

const setter = (obj, is, value) => {
    if (typeof is === 'string') {
        return setter(obj, is.split('.'), value);
    }
    else if (is.length == 1 && value !== undefined) {
        return obj[is[0]] = value;
    }
    else if (is.length == 0) {
        return obj;
    }
    else {
        return setter(obj[is[0]], is.slice(1), value);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
